/* eslint-disable camelcase */
import orderBy from 'lodash/orderBy';
import Auth from 'helpers/auth';
import urlExistSync from 'url-exist-sync';
import EntityType from 'enums/entityType';
import { getEmailTemplateVariableNames } from 'helpers/template';
import {
  formatDate,
  formatNonUtcDate,
  formatDateWithWindow,
  formatMoney,
} from 'helpers/format';
import {
  currentDomainName,
  getJobNumber,
  decodeHtmlEntities,
  generateEmailSignature,
} from 'helpers/general';
import { convertUser } from 'helpers/converters/user';
import {
  convertLockbox,
  generatePropertySlug,
  convertMarket,
  convertLead,
  convertPropertyKeyTag,
  convertApplication as convertApplicationShared,
  convertAppointment as convertAppointmentShared,
} from 'helpers/converters/shared';
import { convertContact } from 'helpers/converters/general';
import CompanyId from 'enums/companyId';

const defaultDateFormat = 'MM/DD/YYYY';

/**
 * Convert direct account
 */
const convertDirectAccount = (account) => {
  if (!account) {
    return null;
  }

  const contactRelations = account.AccountContactRelations || [];
  return {
    id: account.Id || '',
    name: decodeHtmlEntities(account.Name) || '',
    contact1: convertContact(account.Contact_1__r),
    contact2: convertContact(account.Contact_2__r),
    contact3: convertContact(account.Contact_3__r),
    allContacts: contactRelations.map((r) => ({
      ...convertContact(r.Contact),
      type: r.Type__c,
    })),
  };
};

/**
 * Convert inspection
 */
const convertInspection = (inspection) => {
  if (!inspection) {
    return null;
  }

  const property = inspection.Property__r;
  const tenantContract = property?.Active_Tenant_Contract__r;
  const ownerContract = property?.Active_Owner_Contract__r;

  let filterServicePreferredDeliveryMethod =
    inspection.Filter_Service_Preferred_Delivery_Method__c || '';
  let filterServicePreferredDayOfWeek =
    inspection.Filter_Service_Preferred_Day_of_Week__c || '';
  let filterServicePreferredTime =
    inspection.Filter_Service_Preferred_Time_of_Service__c || '';

  // IF one of the fields is NULL look at tenant contract
  if (
    !filterServicePreferredDeliveryMethod ||
    !filterServicePreferredDayOfWeek ||
    !filterServicePreferredTime
  ) {
    filterServicePreferredDeliveryMethod =
      tenantContract?.Filter_Service_Preferred_Delivery_Method__c || '';
    filterServicePreferredDayOfWeek =
      tenantContract?.Filter_Service_Preferred_Day_of_Week__c || '';
    filterServicePreferredTime =
      tenantContract?.Filter_Service_Preferred_Time_of_Service__c || '';
  }

  const acFilterSize = property?.AC_Filter_Size__c
    ? property.AC_Filter_Size__c.split(',').map((item) => item.trim())
    : [];

  const acFilterTools = property?.AC_Filter_Tools__c
    ? property.AC_Filter_Tools__c.split(',').map((item) => item.trim())
    : [];

  const scheduledTech = decodeHtmlEntities(
    inspection.Next_Open_Job_Appointment__r?.Technician__r?.Name ||
      inspection.Next_Open_Job_Appointment__r?.Vendor_Technician_Name__c,
  );

  const scheduledAppointment = inspection.Next_Open_Job_Appointment__r;
  const dateScheduleStart = formatDate(
    scheduledAppointment?.Scheduled_Date_Time__c,
    defaultDateFormat,
  );

  let dateScheduleEnd = '';
  if (scheduledAppointment) {
    dateScheduleEnd = formatDateWithWindow(
      scheduledAppointment.Scheduled_Date_Time__c,
      scheduledAppointment.Schedule_Window__c,
      defaultDateFormat,
    );
  }

  return {
    id: inspection.Id,
    number: inspection.Name || '',
    status: inspection.Status__c || '',
    recordType: inspection.RecordType?.Id || '',
    recordTypeName: inspection.RecordType?.Name || '',
    dateCreated: formatDate(inspection.CreatedDate, defaultDateFormat),
    daysOpen: Number(inspection.Days_Open__c || 0).toFixed(2),
    tenantContact1: convertContact(tenantContract?.Contact_1__r),
    tenantContact2: convertContact(tenantContract?.Contact_2__r),
    tenantContact3: convertContact(tenantContract?.Contact_3__r),
    ownerContact1: convertContact(ownerContract?.Contact_1__r),
    ownerContact2: convertContact(ownerContract?.Contact_2__r),
    ownerContact3: convertContact(ownerContract?.Contact_3__r),
    performedTech: inspection.FastField_User__c || '',
    scheduledTech,
    dateStart: formatDate(inspection.Start_Time__c, defaultDateFormat),
    dateEnd: formatDate(inspection.End_Time__c, defaultDateFormat),
    dateScheduleStart,
    dateScheduleEnd,
    isFilterServiceNeeded: !!property?.Filter_Service_Needed__c,
    isFilterServiceRequested: !!inspection.Filter_Service_Requested__c,
    filterServicePerformed: inspection.Filter_Service_Performed__c || '',
    isFilterServiceConfirmed: !!inspection.Filter_Service_Confirmed__c,
    acFilterSize,
    acFilterTools,
    filterServicePreferredDeliveryMethod,
    filterServicePreferredDayOfWeek,
    filterServicePreferredTime,
    dateReadyToInspect: formatNonUtcDate(
      inspection.Ready_to_Inspect_Date__c,
      defaultDateFormat,
    ),
    internalNotes: decodeHtmlEntities(inspection.Internal_Notes__c),
    currentLeaseId: tenantContract?.Id || '',
    currentLeaseStatus: tenantContract?.Status || '',
    lockbox: convertLockbox(property?.Lockbox__r),
    lockboxInstructions: decodeHtmlEntities(inspection.Lockbox_Instructions__c),
    propertyAccess: {
      alarmCode: property?.Code_Alarm__c || '',
      gateCode: property?.Code_Gate__c || '',
      frontDoorCode: property?.Code_Front_Door__c || '',
      garageCode: property?.Code_Garage__c || '',
      poolCode: property?.Code_Pool__c || '',
    },
    templateData: {
      INSPECTION_TYPE: inspection.RecordType?.Name || '',
      INSPECTION_URL: `${
        ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
      }/inspections/${inspection.Id}`,
      DATE_SCHEDULE: dateScheduleStart,
    },
  };
};

/**
 * Convert work order line items
 */
const convertLineItems = (lineItems = []) => {
  if (!lineItems) {
    return [];
  }
  return lineItems.map((item) => ({
    id: item.Id,
    subject: decodeHtmlEntities(item.Subject),
  }));
};

/**
 * Convert work order
 */
const convertWorkOrder = (workOrder) => {
  if (!workOrder) {
    return null;
  }

  const {
    WORKORDER_TYPE,
    WORKORDER_BUILDER_URL,
    WORKORDER_NUMBER,
    WORKORDER_VIEW_URL,
    WORKORDER_COORDINATOR_NAME,
    WORKORDER_COORDINATOR_EMAIL,
    WORKORDER_COORDINATOR_PHONE,
    WORKORDER_BALANCE,
    WORKORDER_BALANCE_HALF,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: EntityType.WORK_ORDERS,
  });

  const property = workOrder.Property__r;
  const tenantContract = property?.Active_Tenant_Contract__r;
  const ownerContract = property?.Active_Owner_Contract__r;
  const balance = Number(workOrder.WorkOrder_Balance__c || 0);

  return {
    id: workOrder.Id,
    recordType: workOrder.RecordType?.Id || '',
    recordTypeName: workOrder.RecordType?.Name || '',
    number: workOrder.WorkOrderNumber || '',
    status: workOrder.Status || '',
    type: workOrder.Type__c || '',
    subject: decodeHtmlEntities(workOrder.Subject),
    description: decodeHtmlEntities(workOrder.Description),
    internalNotes: decodeHtmlEntities(workOrder.Internal_Notes__c),
    dateCreated: formatDate(workOrder.CreatedDate, defaultDateFormat),
    daysOpen: Number(workOrder.Days_Open__c || 0).toFixed(2),
    dateWorkCompletedExpected: formatNonUtcDate(
      workOrder.Date_Work_Completed_Expected__c,
      defaultDateFormat,
    ),
    preApprovalAmount: workOrder.Pre_Approved_Amount__c || 0,
    protocol: decodeHtmlEntities(workOrder.Work_Order_Protocol__c),
    isHomeWarranty: !!workOrder.Home_Warranty__c,
    ownerContact1: convertContact(ownerContract?.Contact_1__r),
    ownerContact2: convertContact(ownerContract?.Contact_2__r),
    ownerContact3: convertContact(ownerContract?.Contact_3__r),
    tenantContact1: convertContact(tenantContract?.Contact_1__r),
    tenantContact2: convertContact(tenantContract?.Contact_2__r),
    tenantContact3: convertContact(tenantContract?.Contact_3__r),
    lineItems: convertLineItems(workOrder.WorkOrderLineItems?.records),
    isWorkOrderEditable: Auth.isEmployeeCanEditWorkOrder(
      workOrder.RecordType?.Id || '',
    ),
    templateData: {
      [WORKORDER_TYPE]: workOrder.Type__c || '',
      [WORKORDER_BUILDER_URL]: `${
        ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
      }/work-orders/${workOrder.Id}/builder`,
      [WORKORDER_NUMBER]: workOrder.WorkOrderNumber || '',
      [WORKORDER_VIEW_URL]: `${
        ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
      }/work-orders/${workOrder.Id}`,
      [WORKORDER_COORDINATOR_NAME]: workOrder.Coordinator__r?.Name || '',
      [WORKORDER_COORDINATOR_EMAIL]: workOrder.Coordinator__r?.Email || '',
      [WORKORDER_COORDINATOR_PHONE]: workOrder.Coordinator__r?.Phone || '',
      [WORKORDER_BALANCE]: formatMoney(balance),
      [WORKORDER_BALANCE_HALF]: formatMoney(balance / 2),
    },
  };
};

/**
 * Convert property
 */
const convertProperty = ({
  property,
  currentUser,
  companyInfo,
  markets = [],
}) => {
  if (!property) {
    return null;
  }

  const {
    PROPERTY_ADDRESS,
    PROPERTY_STREET,
    REPORT_ID,
    REPORT_URL,
    PROPERTY_DASHBOARD_URL,
    CURRENT_RENT_RATE,
    RENT_ESTIMATE,
    TARGET_RENT_RATE,
    MARKETING_RENTAL_RATE,
    PM_NAME,
    PM_PHONE,
    PM_EMAIL,
    PM_PICTURE,
    // assistant
    PM_ASSISTANT_EMAIL,
    EMAIL_SIGNATURE,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: EntityType.PROPERTIES,
  });

  const tenantContract = property.Active_Tenant_Contract__r;
  const prevTenantContract = property.Previous_Tenant_Agreement__r;
  const ownerContract = property.Active_Owner_Contract__r;
  const propertyManager = (ownerContract || tenantContract)
    ?.Property_Manager__r;
  const assistant = propertyManager?.Assistant__r;

  // IF no active lease, look for Previous_Tenant_Agreement__r.Date_Keys_Returned__c
  const dateKeysReturned =
    tenantContract?.Date_Keys_Returned__c ||
    prevTenantContract?.Date_Keys_Returned__c;

  const acFilterSize = property.AC_Filter_Size__c
    ? property.AC_Filter_Size__c.split(',').map((item) => item.trim())
    : [];

  const acFilterTools = property.AC_Filter_Tools__c
    ? property.AC_Filter_Tools__c.split(',').map((item) => item.trim())
    : [];

  const convertedPm = convertUser(propertyManager);
  const convertedPmAssistant = convertUser(assistant);
  const imageUrl = convertedPm?.image || '';
  const validUrl = urlExistSync(imageUrl);
  const pmNameValue = convertedPm?.name || '';
  const pmEmailValue = convertedPm?.email || currentUser?.email || '';
  const pmAssistantEmailValue = convertedPmAssistant?.email || pmEmailValue;
  const pmPictureValue = validUrl
    ? `<img alt="${pmNameValue}" src="${imageUrl}" width="80">`
    : '';
  const emailSignatureValue = generateEmailSignature(
    convertedPm,
    companyInfo,
    markets,
  );

  const propertySlug = generatePropertySlug({
    streetAddress: property.Street_Address__c,
    zip: property.Zip_Code__c,
    building: property.Building__c,
    unit: property.Unit__c,
  });

  return {
    id: property.Id || '',
    fullAddress: property.Full_Address__c || '',
    streetAddress: property.Street_Address__c || '',
    city: property.City__c || '',
    state: property.State__c || '',
    zip: property.Zip_Code__c || '',
    building: property.Building__c || '',
    unit: property.Unit__c || '',
    activeTenantContract: convertTenantContract({
      contract: tenantContract,
      currentUser,
    }),
    market: ownerContract?.Market__r?.Name || '',
    propertyManager: convertedPm,
    assistant: convertedPmAssistant,
    dateKeysReturned: formatDate(dateKeysReturned, defaultDateFormat),
    utilitiesConfirmationStatus:
      property.Utilities_Confirmation_Status__c || '',
    lockbox: convertLockbox(property?.Lockbox__r),
    propertyType: property.Property_Type__c || '',
    keyTagObject: convertPropertyKeyTag(property.Property_Key_Tag__r),
    yearBuilt: property.Year_Built__c || '',
    squareFeet: property.Square_Footage__c || 0,
    floors: property.Number_of_Floors__c || 1,
    bedRooms: property.Number_of_Beds__c || 0,
    bathRooms: property.Number_of_Baths__c || 0,
    acFilterSize,
    acFilterTools,
    parkingType: property.Parking_Type__c || '',
    numOfParkingSpaces: property.Parking_Spaces__c || 0,
    mailboxLocation: property.Mailbox_Location__c || '',
    isHaveSwimingPool: !!property.Swimming_Pool__c,
    alarmCode: property.Code_Alarm__c || '',
    gateCode: property.Code_Gate__c || '',
    frontDoorCode: property.Code_Front_Door__c || '',
    garageCode: property.Code_Garage__c || '',
    poolCode: property.Code_Pool__c || '',
    dateFilterServiced: formatNonUtcDate(
      property.Date_Filter_Serviced__c,
      defaultDateFormat,
    ),
    isFilterServiceNeeded: !!property.Filter_Service_Needed__c,
    directAccount: convertDirectAccount(property.Account__r),
    matterportLink: property.Matterport_Link__c || '',
    workOrderProtocol: property.Work_Order_Protocol__c || '',
    templateData: {
      // property
      [PROPERTY_ADDRESS]: property.Full_Address__c || '',
      [PROPERTY_STREET]: property.Street_Address__c || '',
      [REPORT_ID]: propertySlug || '',
      [REPORT_URL]: `${
        ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
      }/rental-analysis-report/${propertySlug}`, // slug
      [PROPERTY_DASHBOARD_URL]: `${
        ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
      }/properties/${property.Id}`,
      [CURRENT_RENT_RATE]: property.Total_Rent_Rate__c || 0, // the same we have in tenant contract
      [RENT_ESTIMATE]: property.rent_estimate__c || 0,
      [TARGET_RENT_RATE]: ownerContract?.Target_Rent_Rate_Owner__c || 0,
      [MARKETING_RENTAL_RATE]:
        property.Property_Listing__r?.Marketing_Rental_Rate__c || 0,
      // manager
      [PM_NAME]: pmNameValue,
      [PM_PHONE]: convertedPm?.phone || '',
      [PM_EMAIL]: pmEmailValue,
      [PM_PICTURE]: pmPictureValue,
      // assistant
      [PM_ASSISTANT_EMAIL]: pmAssistantEmailValue,
      [EMAIL_SIGNATURE]: emailSignatureValue,
    },
  };
};

/**
 * Convert property listing
 */
const convertPropertyListing = ({ listing }) => {
  if (!listing) {
    return null;
  }

  const { PROPERTY_DASHBOARD_URL, APPLICATION_URL } =
    getEmailTemplateVariableNames({
      useArea: 'communication',
      type: EntityType.LISTINGS,
    });

  const ownerContract = listing.Property__r?.Active_Owner_Contract__r || null;

  return {
    id: listing.Id || '',
    status: listing.Status__c || '',
    selfShowing: listing.Self_Showing__c || '',
    isMlsApproved: !!listing.MLS_Approved__c,
    isMlsCreated: !!listing.MLS_Created__c,
    marketingReportUrl: listing.Marketing_Report_URL__c || '',
    marketingRentalRate: listing.Marketing_Rental_Rate__c || 0,
    dateAvailable: formatNonUtcDate(
      listing.Available_Date__c,
      defaultDateFormat,
    ),
    dateReadyToMarket: formatNonUtcDate(
      listing.Ready_to_Market_Date__c,
      defaultDateFormat,
    ),
    marketingNotes: listing.Marketing_Notes__c || '',
    additionalLeaseTermsOwner: listing.Additional_Lease_Terms_Owner__c || '',
    templateData: {
      [PROPERTY_DASHBOARD_URL]: `${
        ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
      }/properties/${listing.Property__c}`,
      [APPLICATION_URL]: listing.Application_URL__c,
    },
  };
};

/**
 * Convert tenant contract
 */
const convertTenantContract = ({ contract, currentUser }) => {
  if (!contract) {
    return null;
  }

  const {
    TENANT_NAMES,
    TENANT_STARTDATE,
    TENANT_ENDDATE,
    CURRENT_RENT_RATE,
    CURRENT_BASE_RENT,
    TENANT_NEW_ENDDATE,
    PUBLIC_ACCESS_CODE,
    NEW_RENT,
    RENT_DIFF,
    PM_EMAIL,
    PM_ASSISTANT_EMAIL,
    ADDENDUM_ID,
    ADDENDUM_COMMENTS,
    ADDENDUM_OFFER_MONTH_TO_MONTH,
    REVIEW_LEASE_RENEWAL_URL,
    TENANT_CONTRACT_NUMBER,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: EntityType.CONTRACTS,
  });

  const dateStart = formatNonUtcDate(contract.StartDate, defaultDateFormat);
  const dateEnd = formatNonUtcDate(contract.EndDate, defaultDateFormat);
  const tenantNamesValue = [
    contract.Contact_1__r?.Name,
    contract.Contact_2__r?.Name,
    contract.Contact_3__r?.Name,
  ]
    .filter(Boolean)
    .join(', ');

  const addendum = contract.Contract_Addendum__r;
  const propertyManager = contract.Property_Manager__r;
  const convertedPm = convertUser(propertyManager);

  const assistant = propertyManager?.Assistant__r;
  const convertedPmAssistant = convertUser(assistant);

  const pmEmailValue = convertedPm?.email || currentUser?.email || '';
  const pmAssistantEmailValue = convertedPmAssistant?.email || pmEmailValue;

  const newRentValue = addendum?.Rent_Rate_monthly_w_Admin_Fee__c || '';
  const newRentNumber = newRentValue !== '' ? newRentValue : 0;

  const currentRent = contract.Rent_Rate_monthly_w_Admin_Fee__c || 0;
  const rentDiffValue = (
    Math.round((newRentNumber - currentRent) * 100) / 100
  ).toFixed(2);

  const contactRelations = contract.AccountContactRelations || [];

  const contractNumber = contract?.ContractNumber
    ? Number(contract?.ContractNumber).toString()
    : '';

  return {
    id: contract.Id || '',
    dateStart,
    dateEnd,
    status: contract.Status || '',
    name: contract.Name || '',
    contractNumber,
    leasingAgentId: contract.Leasing_Agent__r?.Id || '',
    leasingAgentName: contract.Leasing_Agent__r?.Name || '',
    noticeToVacate: contract.Notice_to_Vacate__c || '',
    contractTerm: contract.ContractTerm || '',
    accountId: contract.Account?.Id || '',
    dateMoveIn: formatNonUtcDate(contract.Move_In_Date__c, defaultDateFormat),
    dateMoveOut: formatNonUtcDate(contract.Move_Out_Date__c, defaultDateFormat),
    contact1: convertContact(contract.Contact_1__r),
    contact2: convertContact(contract.Contact_2__r),
    contact3: convertContact(contract.Contact_3__r),
    allContacts: contactRelations.map((r) => ({
      ...convertContact(r.Contact),
      type: r.Type__c,
    })),
    rentRateBreakdown: contract.Total_Rent_Rate_Breakdown__c || '',
    residentBenefitsPackage: contract.Resident_Benefits_Package__c || '',
    residentBenefitsPackagePrice:
      contract.Resident_Benefits_Package_Price__c || 0,
    adminTaxPercentage: contract.Admin_Tax_Percentage__c || 0,
    isOfferSecurityDepositAlternative:
      !!contract.Security_Deposit_Alternative_Offer__c,
    securityDeposit: contract.Deposit__c || 0,
    securityDepositType: contract.Security_Deposit_Type__c || '',
    securityDepositAlternativePercentage:
      contract.Security_Deposit_Alternative_Percentage__c || 0,
    securityDepositRefundable: contract.Security_Deposit_Refundable__c || '',
    holdingDepositPaymentMethod:
      contract.Holding_Deposit_Payment_Method__c || '',
    rentRateLease: contract.Rent_Rate_Lease__c || 0,
    parkingRent: contract.Parking_Rent__c || 0,
    storageRent: contract.Storage_Rent__c || 0,
    rubs: contract.RUBS__c || 0,
    otherRent: contract.Other_Rent__c || 0,
    otherRentDescription: decodeHtmlEntities(
      contract.Other_Rent_Description__c,
    ),
    leaseSigningFee: contract.Lease_Signing_Fee__c || 0,
    petRent: contract.Pet_Rent__c || 0,
    petDeposit: contract.Pet_Deposit__c || 0,
    petFeeOnQ: contract.Pet_Fee_On_Q__c || 0,
    petFeeOwner: contract.Pet_Fee__c || 0,
    otherDeposit: contract.Other_Deposit__c || 0,
    otherDepositDescription: contract.Other_Deposit_Description__c || '',
    concessions: contract.Concessions__c || 0,
    isOtherDepositRefundable: !!contract.Other_Deposit_Refundable__c,
    preferredLeaseTerms: contract.Lease_Terms__c || '',
    additionalTerms: contract.SpecialTerms || '',
    additionalOccupants: contract.Additional_Occupants__c || '',
    templateData: {
      [TENANT_NAMES]: tenantNamesValue,
      [TENANT_STARTDATE]: dateStart,
      [TENANT_ENDDATE]: dateEnd,
      [CURRENT_RENT_RATE]: contract.Total_Rent_Rate__c || 0,
      [CURRENT_BASE_RENT]: contract.Rent_Rate_Lease__c || 0,
      [TENANT_NEW_ENDDATE]: formatNonUtcDate(
        addendum?.Contract_End_Date__c,
        defaultDateFormat,
      ),
      [PUBLIC_ACCESS_CODE]: addendum?.Public_Access_Code__c || '',
      [NEW_RENT]: newRentValue,
      [RENT_DIFF]: rentDiffValue,
      [PM_EMAIL]: pmEmailValue,
      [PM_ASSISTANT_EMAIL]: pmAssistantEmailValue,
      [ADDENDUM_ID]: addendum?.Id || '',
      [ADDENDUM_COMMENTS]: addendum?.Comments__c || '',
      [ADDENDUM_OFFER_MONTH_TO_MONTH]: addendum?.Offer_Month_to_Month__c
        ? 'Yes'
        : 'No',
      [REVIEW_LEASE_RENEWAL_URL]: `${process.env.ONQ_DOMAIN}/lease-renewals/${addendum?.Id}?pac=${addendum?.Public_Access_Code__c}`,
      [TENANT_CONTRACT_NUMBER]: contractNumber,
    },
  };
};

/**
 * Convert owner contract
 */
const convertOwnerContract = ({ contract, currentUser }) => {
  if (!contract) {
    return null;
  }

  const {
    OWNER_NAMES,
    OWNER_STARTDATE,
    OWNER_ENDDATE,
    MAINTENANCE_LIMIT,
    PM_EMAIL,
    PM_ASSISTANT_EMAIL,
    OWNER_CONTRACT_NUMBER,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: EntityType.CONTRACTS,
  });

  const dateStart = formatNonUtcDate(contract.StartDate, defaultDateFormat);
  const dateEnd = formatNonUtcDate(contract.EndDate, defaultDateFormat);
  const ownerNamesValue = [
    contract.Contact_1__r?.Name,
    contract.Contact_2__r?.Name,
    contract.Contact_3__r?.Name,
  ]
    .filter(Boolean)
    .join(', ');

  const propertyManager = contract.Property_Manager__r;
  const convertedPm = convertUser(propertyManager);

  const assistant = propertyManager?.Assistant__r;
  const convertedPmAssistant = convertUser(assistant);

  const pmEmailValue = convertedPm?.email || currentUser?.email || '';
  const pmAssistantEmailValue = convertedPmAssistant?.email || pmEmailValue;

  const contactRelations = contract.AccountContactRelations || [];

  const contractNumber = contract?.ContractNumber
    ? Number(contract?.ContractNumber).toString()
    : '';

  return {
    id: contract.Id || '',
    accountId: contract.Account?.Id || '',
    ownerPortfolio: decodeHtmlEntities(contract.Account?.Name),
    market: contract.Market__r?.Name || '',
    status: contract.Status || '',
    dateStart,
    dateEnd,
    contractNumber,
    contact1: convertContact(contract.Contact_1__r),
    contact2: convertContact(contract.Contact_2__r),
    contact3: convertContact(contract.Contact_3__r),
    allContacts: contactRelations.map((r) => ({
      ...convertContact(r.Contact),
      type: r.Type__c,
    })),
    evictionProtection: contract.Combined_Eviction_Protection__c || '',
    routineInspections: contract.Routine_Inspection__c || '',
    dateNextRoutineRequest: formatNonUtcDate(
      contract.Date_Routine_Inspection_Next_Request__c,
      defaultDateFormat,
    ),
    dateLastRoutineRequest: formatNonUtcDate(
      contract.Date_Routine_Inspection_Last_Request__c,
      defaultDateFormat,
    ),
    filterService: contract.Filter_Service__c || '',
    dateNextFilterServiceRequest: formatNonUtcDate(
      contract.Date_Filter_Service_Next_Request__c,
      defaultDateFormat,
    ),
    dateLastFilterServiceRequest: formatNonUtcDate(
      contract.Date_Filter_Service_Last_Request__c,
      defaultDateFormat,
    ),
    workOrderProtocol: decodeHtmlEntities(contract.Work_Order_Protocol__c),
    isWorkOrderExcludeMammoth: !!contract.Work_Order_Exclude_Mammoth__c,
    templateData: {
      [OWNER_NAMES]: ownerNamesValue,
      [OWNER_STARTDATE]: dateStart,
      [OWNER_ENDDATE]: dateEnd,
      [MAINTENANCE_LIMIT]: contract.Maintenance_Limit__c || 0,
      [PM_EMAIL]: pmEmailValue,
      [PM_ASSISTANT_EMAIL]: pmAssistantEmailValue,
      [OWNER_CONTRACT_NUMBER]: contractNumber,
    },
  };
};

/**
 * Convert opportunity
 */
const convertOpportunity = ({ opportunity, currentUser, companyInfo }) => {
  if (!opportunity) {
    return null;
  }

  const {
    OWNER_NAMES,
    // manager
    PM_NAME,
    PM_PHONE,
    PM_EMAIL,
    PM_PICTURE,
    // assistant
    PM_ASSISTANT_EMAIL,
    ONBOARDING_NAME,
    ONBOARDING_EMAIL,
    ESTIMATOR_NAME,
    ESTIMATOR_EMAIL,
    // opportunity owner
    SALES_NAME,
    SALES_EMAIL,
    SALES_PHONE,
    // occupancy variables
    PROPERTY_OCCUPANCY_SUMMARY,
    IS_ANY_OPPORTUNITY_PROPERTY_VACANT,
    IS_ANY_OPPORTUNITY_PROPERTY_OCCUPIED,
    EMAIL_SIGNATURE,
    MARKET,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: EntityType.OPPORTUNITIES,
  });
  const { propertyOccupancySummary } = opportunity;
  const propertyManager = opportunity.Property_Manager__r;
  const assistant = propertyManager?.Assistant__r;
  const convertedPm = convertUser(propertyManager);
  const convertedPmAssistant = convertUser(assistant);

  const salesNameValue = opportunity.Owner?.Name || '';
  const salesEmailValue = opportunity.Owner?.Email || '';
  const salesPhoneValue = opportunity.Owner?.Phone || '';
  const emailSignatureValue = generateEmailSignature(convertedPm, companyInfo);

  const pmNameValue = convertedPm?.name || '';
  const pmEmailValue = convertedPm?.email || currentUser?.email || '';
  const imageUrl = convertedPm?.image || '';
  const validUrl = urlExistSync(imageUrl);
  const pmPictureValue = validUrl
    ? `<img alt="${pmNameValue}" src="${imageUrl}" width="80">`
    : '';
  const pmAssistantEmailValue = convertedPmAssistant?.email || pmEmailValue;

  const onboardingNameValue = opportunity.Onboarding_Coordinator__r?.Name || '';
  const onboardingEmailValue =
    opportunity.Onboarding_Coordinator__r?.Email || '';
  const estimatorNameValue = opportunity.Mammoth_Estimator__r?.Name || '';
  const estimatorEmailValue = opportunity.Mammoth_Estimator__r?.Email || '';

  const ownerNamesValue = [
    opportunity.Contact_1__r?.Name,
    opportunity.Contact_2__r?.Name,
    opportunity.Contact_3__r?.Name,
  ]
    .filter(Boolean)
    .join(', ');

  const contactRelations = opportunity.Account?.AccountContactRelations || [];

  return {
    id: opportunity.Id || '',
    contact1: convertContact(opportunity.Contact_1__r),
    contact2: convertContact(opportunity.Contact_2__r),
    contact3: convertContact(opportunity.Contact_3__r),
    allContacts: contactRelations.map((r) => ({
      ...convertContact(r.Contact),
      type: r.Type__c,
    })),
    stage: opportunity.StageName || '',
    marketName: opportunity.Market__r?.Name || '',
    market: opportunity.Market__r?.Id || '',
    dateProjectedStart: formatDate(
      opportunity.Projected_Start_Date__c,
      defaultDateFormat,
    ),
    dateClosed: formatNonUtcDate(opportunity.CloseDate, defaultDateFormat),
    accountId: opportunity.Account?.Id || '',
    accountName: decodeHtmlEntities(opportunity.Account?.Name),
    numberOfProperties: opportunity.Number_of_Properties__c || 0,
    propertyOccupancySummary,
    dateLastSave: formatDate(
      opportunity.Timestamp_Last_Portal_Save__c,
      'MM/DD/YYYY @ h:mma',
    ),
    opportunityNotes: decodeHtmlEntities(opportunity.Description),
    leadNotes: decodeHtmlEntities(opportunity.Lead_Description__c),
    ocNotes: decodeHtmlEntities(opportunity.oc_Notes__c),
    propertyManager: convertedPm,
    assistant: convertedPmAssistant,
    owner: convertUser(opportunity.Owner),
    templateData: {
      [OWNER_NAMES]: ownerNamesValue,
      // manager
      [PM_NAME]: pmNameValue,
      [PM_PHONE]: convertedPm?.phone || '',
      [PM_EMAIL]: pmEmailValue,
      [PM_PICTURE]: pmPictureValue,
      // assistant
      [PM_ASSISTANT_EMAIL]: pmAssistantEmailValue,
      [ONBOARDING_NAME]: onboardingNameValue,
      [ONBOARDING_EMAIL]: onboardingEmailValue,
      [ESTIMATOR_NAME]: estimatorNameValue,
      [ESTIMATOR_EMAIL]: estimatorEmailValue,
      // opportunity owner
      [SALES_NAME]: salesNameValue,
      [SALES_EMAIL]: salesEmailValue,
      [SALES_PHONE]: salesPhoneValue,
      // occupancy variables
      [PROPERTY_OCCUPANCY_SUMMARY]: propertyOccupancySummary,
      [IS_ANY_OPPORTUNITY_PROPERTY_VACANT]: [
        'Mixed Occupancy',
        'Vacant',
      ].includes(propertyOccupancySummary),
      [IS_ANY_OPPORTUNITY_PROPERTY_OCCUPIED]: [
        'Mixed Occupancy',
        'Occupied',
      ].includes(propertyOccupancySummary),
      [EMAIL_SIGNATURE]: emailSignatureValue,
      [MARKET]: opportunity.Market__r?.Name || '',
    },
  };
};

/**
 * Convert lead
 */
const convertLeadForCommunication = ({ lead }) => {
  if (!lead) {
    return null;
  }

  return {
    ...(convertLead(lead) || {}),
    templateData: {},
  };
};

/**
 * Convert locker
 */
const convertLocker = ({ locker }) => {
  if (!locker) {
    return null;
  }

  const {
    DATE_EXPECTED_RETRIEVAL,
    CONFIRMATION_CODE,
    CONTENTS_IMAGE_LINK,
    PUBLIC_DESCRIPTION,
    DATE_CONTRACT_START,
    DATE_MOVE_IN,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: EntityType.LOCKERS,
  });

  const contractNumber = locker.Contract__r?.ContractNumber
    ? Number(locker.Contract__r?.ContractNumber).toString()
    : '';

  const dateExpectedRetrieval = formatNonUtcDate(
    locker.Expected_Retrieval_Date__c || '',
    defaultDateFormat,
  );

  return {
    id: locker.Id || '',
    status: locker.Status__c || '',
    lockerType: locker.Locker_Type__c || '',
    number: locker.Locker_Number__c || 0,
    location: locker.Location__c || '',
    directAccount: convertDirectAccount(locker.Account__r),
    convenienceScore: locker.Convenience_Score__c || '',
    accessCode: locker.Code__c || '',
    adminCode: locker.Admin_Code__c || '',
    isCodeActivated: !!locker.Access_Code_Activated__c,
    confirmationCode: locker.Confirmation_Code__c || '',
    masterCode: locker.Master_Code__c || '',
    manualResetCode: locker.Manual_Reset_Code__c || '',
    codeInstructions: decodeHtmlEntities(locker.Code_Instructions__c),
    resetInstructions: decodeHtmlEntities(locker.Reset_Instructions__c),
    dateOccupied: formatNonUtcDate(
      locker.Occupied_Date__c || '',
      defaultDateFormat,
    ),
    daysSinceOccupancy: locker.Days_Since_Occupancy__c || 0,
    filledByName: locker.Filled_By__r?.Name || '',
    dateExpectedRetrieval,
    daysSinceExpectedPickup: locker.Days_Since_Expected_Pickup__c || 0,
    dateRetrieved: formatDate(
      locker.Retrieved_Date__c || '',
      'MM/DD/YYYY @ h:mma',
    ),
    contractNumber,
    accountName: decodeHtmlEntities(locker.Account__r?.Name),
    contentsImage: locker.Contents_Image__c || '',
    contentsDescription: decodeHtmlEntities(locker.Contents_Description__c),
    publicDescription: decodeHtmlEntities(locker.Public_Description__c),
    isMoveInFundsCollected: !!locker.Contract__r?.Move_In_Funds_Collected__c,
    oneTimeQrCodeLink: locker.OneTimeQrCodeLink || '',
    templateData: {
      [DATE_EXPECTED_RETRIEVAL]: dateExpectedRetrieval,
      [CONFIRMATION_CODE]: locker.Confirmation_Code__c || '',
      [CONTENTS_IMAGE_LINK]: locker.Contents_Image__c || '',
      [PUBLIC_DESCRIPTION]: locker.Public_Description__c || '',
      [DATE_CONTRACT_START]: formatNonUtcDate(
        locker.Contract__r?.StartDate || '',
        defaultDateFormat,
      ),
      [DATE_MOVE_IN]: formatNonUtcDate(
        locker.Contract__r?.Move_In_Date__c || '',
        defaultDateFormat,
      ),
    },
    market: convertMarket(locker.Market__r),
  };
};

/**
 * Convert invoice
 */
const convertInvoice = (invoice) => {
  if (!invoice) {
    return null;
  }

  const {
    INVOICE_NUMBER,
    INVOICE_PORTAL_URL,
    INVOICE_BALANCE_DUE,
    WORK_ORDER_NUMBER,
    WORK_ORDER_BUILDER_URL,
    WORK_ORDER_VIEWER_URL,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: EntityType.INVOICES,
  });

  const number = getJobNumber(invoice.Name);
  const contactRelations =
    (invoice.Bill_To_Contract__r || invoice.Bill_To_Direct__r)
      ?.AccountContactRelations || [];
  const ownerContract =
    invoice.Work_Order__r?.Property__r?.Active_Owner_Contract__r || null;

  const viewerUrl = `${
    ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
  }/work-orders/${invoice.Work_Order__r?.Id}`;

  return {
    id: invoice.Id || '',
    invoiceNumber: number,
    dateCreated: formatDate(invoice.CreatedDate || '', 'MM/DD/YYYY'),
    billingMethod: invoice.Billing_Method__c || '',
    billToName:
      invoice.Bill_To_Direct__r?.Name ||
      invoice.Bill_To_Contract__r?.Account?.Name ||
      '',
    billToContacts: contactRelations.map((r) => ({
      ...convertContact(r.Contact),
      type: r.Type__c,
    })),
    status: invoice.Status__c || '',
    invoiceType: invoice.RecordType?.Name || '',
    isBillCreated: !!invoice.Bill_Created__c,
    total: invoice.Grand_Total__c || 0,
    paid: invoice.Payments_Received__c || 0,
    balance: invoice.Balance_Due__c || 0,
    numberOfPayments: invoice.Number_of_Payments__c || 0,
    templateData: {
      [INVOICE_NUMBER]: number,
      [INVOICE_PORTAL_URL]: `${
        ownerContract ? process.env.ONQ_DOMAIN : currentDomainName
      }/invoices/${invoice.Id}`,
      [INVOICE_BALANCE_DUE]: invoice.Balance_Due__c || 0,
      [WORK_ORDER_NUMBER]: invoice.Work_Order__r?.WorkOrderNumber || '',
      [WORK_ORDER_BUILDER_URL]: `${viewerUrl}/builder`,
      [WORK_ORDER_VIEWER_URL]: `${viewerUrl}?invoiceId=${invoice.Id}`,
    },
  };
};

/**
 * Convert job
 */
const convertJob = (job) => {
  if (!job) {
    return null;
  }

  const { VENDOR_NAME, JOB_PORTAL_URL, JOB_NUMBER } =
    getEmailTemplateVariableNames({
      useArea: 'communication',
      type: EntityType.JOBS,
    });

  const number = getJobNumber(job.Name);
  const technician =
    job.Next_Open_Job_Appointment__r?.Technician__r?.Name ||
    job.Next_Open_Job_Appointment__r?.Vendor_Technician_Name__c ||
    '';

  const vendorName = decodeHtmlEntities(job.Vendor__r?.Name);

  return {
    technician,
    id: job.Id || '',
    jobNumber: number,
    status: job.Status__c || '',
    subject: decodeHtmlEntities(job.Subject__c),
    vendorName,
    vendor: {
      name: vendorName,
      email: job.Vendor__r?.Email__c || '',
    },
    maintenanceGroup: job.Agent_Group__r?.Id || '',
    maintenanceGroupName: job.Agent_Group__r?.Name || '',
    dateScheduled: formatDate(job.Scheduled_Date_Time__c, defaultDateFormat),
    templateData: {
      [VENDOR_NAME]: vendorName,
      [JOB_PORTAL_URL]: `${currentDomainName}/jobs/${job.Id}`,
      [JOB_NUMBER]: number,
    },
  };
};

/**
 * Convert appointment
 */
const convertAppointment = (app) => {
  if (!app) {
    return null;
  }

  const appointment = convertAppointmentShared(app);

  const dateScheduled = formatDate(
    app?.Scheduled_Date_Time__c,
    'MM/DD/YYYY @ h:mma',
  );

  const technicianName =
    app?.Technician__r?.Name || app?.Vendor_Technician_Name__c || '';

  return {
    ...appointment,
    technicianName,
    jobId: app.Job__c || '',
    dateScheduled,
  };
};

const convertApplication = (app) => {
  if (!app) {
    return null;
  }

  const application = convertApplicationShared(app);

  application.account.allContacts = (
    app.Account__r.AccountContactRelation || []
  ).map((r) => ({
    ...convertContact(r.Contact),
    type: r.Type__c,
  }));

  return application;
};

/**
 * Convert entity
 */
const convertEntityData = ({
  entity,
  entityType,
  currentUser,
  companyInfo,
  markets,
}) => {
  // set defaults
  let results = {
    inspection: null,
    workOrders: null,
    property: null,
    owner: null,
    lease: null,
    opportunity: null,
    lead: null,
    listing: null,
    locker: null,
    invoice: null,
    job: null,
    appointment: null,
    application: null,
  };

  // convert data
  switch (entityType) {
    case EntityType.INSPECTIONS:
    case EntityType.WORK_ORDERS:
      {
        // prepare convert function
        let entityName = 'inspection';
        let convertFunction = convertInspection;
        if (entityType === EntityType.WORK_ORDERS) {
          entityName = 'workOrder';
          convertFunction = convertWorkOrder;
        }
        // convert data
        results = {
          ...results,
          [entityName]: convertFunction(entity),
          property: convertProperty({
            property: entity?.Property__r,
            currentUser,
            companyInfo,
            markets,
          }),
          owner: convertOwnerContract({
            contract: entity?.Property__r?.Active_Owner_Contract__r,
            currentUser,
          }),
          lease: convertTenantContract({
            contract: entity?.Property__r?.Active_Tenant_Contract__r,
            currentUser,
          }),
          invoice: convertInvoice(entity.Income__r),
          job: convertJob(entity.Job__r),
          appointment: convertAppointment(entity.Job__r?.Appointment__r),
        };
      }
      break;

    case EntityType.PROPERTIES:
      results = {
        ...results,
        property: convertProperty({
          property: entity,
          currentUser,
          companyInfo,
          markets,
        }),
        owner: convertOwnerContract({
          contract: entity?.Active_Owner_Contract__r,
          currentUser,
        }),
        lease: convertTenantContract({
          contract: entity?.Active_Tenant_Contract__r,
          currentUser,
        }),
        listing: convertPropertyListing({
          listing: entity?.Property_Listing__r,
        }),
        application: convertApplication(entity?.Application__r),
      };
      break;

    case EntityType.OPPORTUNITIES:
      results = {
        ...results,
        opportunity: convertOpportunity({
          opportunity: entity,
          currentUser,
          companyInfo,
        }),
      };
      break;

    case EntityType.LEADS:
      results = {
        ...results,
        lead: convertLeadForCommunication({
          lead: entity,
        }),
      };
      break;

    case EntityType.LOCKERS:
      results = {
        ...results,
        locker: convertLocker({ locker: entity }),
        lease: convertTenantContract({
          contract: entity?.Contract__r,
          currentUser,
        }),
      };
      break;

    case EntityType.CONTRACTS:
      {
        /* eslint-disable indent */
        const contractType = entity.Record_Type__c || '';
        results = {
          ...results,
          owner:
            contractType === 'Owner Agreement'
              ? convertOwnerContract({
                  contract: entity,
                  currentUser,
                })
              : null,
          lease:
            contractType === 'Lease Agreement'
              ? convertTenantContract({
                  contract: entity,
                  currentUser,
                })
              : null,
        };
        /* eslint-enable indent */
      }
      break;

    case EntityType.APPLICATIONS:
      {
        /* eslint-disable indent */
        const application = convertApplication(entity);

        results = {
          ...results,
          entity: application,
          application,
          lease: convertTenantContract({
            contract: entity.Contract__r,
            currentUser,
          }),
          property: convertProperty({
            property: entity.Property__r,
            currentUser,
            companyInfo,
            markets,
          }),
        };
        /* eslint-enable indent */
      }
      break;

    default:
  }

  return results;
};

/**
 * Convert activity
 */
const convertActivity = (activity) => {
  if (!activity) {
    return null;
  }
  return {
    id: activity.Id || '',
    taskType: activity.Type || 'Task',
    subject: activity.Subject || '',
    whatId: activity.WhatId || '',
    whoId: activity.WhoId || '',
    assignedTo: activity.Owner?.Name || '',
    assignedToId: activity.Owner?.Id || '',
    status: activity.Status || '',
    dateCreated: formatDate(activity.CreatedDate, 'MM/DD/YYYY @ h:mma'),
    dateModified: formatDate(activity.LastModifiedDate, 'MM/DD/YYYY @ h:mma'),
    dateActivity: formatNonUtcDate(activity.ActivityDate, defaultDateFormat),
    description: activity.Description || '',
  };
};

/**
 * Group activities by WhoId
 */
const groupActivities = (activities = []) => {
  if (!activities || activities.length === 0) {
    return {};
  }

  return activities.reduce((memo, activity) => {
    const converted = convertActivity(activity);
    const id = converted.whatId || converted.whoId;
    if (converted && id) {
      if (!memo[id]) {
        memo[id] = [];
      }
      memo[id].push(converted);
    }
    return memo;
  }, {});
};

const entityTypeToTabName = {
  [EntityType.INSPECTIONS]: 'inspection',
  [EntityType.WORK_ORDERS]: 'workOrder',
  [EntityType.OPPORTUNITIES]: 'opportunity',
  [EntityType.LEADS]: 'lead',
  [EntityType.PROPERTIES]: 'property',
  [EntityType.LISTINGS]: 'listing',
  [EntityType.LOCKERS]: 'locker',
  [EntityType.INVOICES]: 'invoice',
  [EntityType.JOBS]: 'job',
  [EntityType.APPLICATIONS]: 'application',
  [EntityType.APPOINTMENTS]: 'appointment',
  // owner and lease will be added programmatically
};
/**
 * Group email templates by entityType
 */
const groupEmailTemplates = (templates = []) => {
  if (!templates || templates.length === 0) {
    return {};
  }

  return templates.reduce((memo, template) => {
    // detect for which primary tab it is template is related to
    let key = entityTypeToTabName[template.entityType];
    // for contracts entity type need to detect if it is lease or owner
    if (template.entityType === EntityType.CONTRACTS) {
      key = template.entitySubType?.includes('tenant') ? 'lease' : 'owner';
    }
    if (!key) {
      return memo;
    }

    if (!memo[key]) {
      memo[key] = [];
    }
    memo[key].push(template);
    return memo;
  }, {});
};

/**
 * Get Email data
 */
const getEmailData = ({
  job = null,
  invoice = null,
  property = null,
  opportunity = null,
  directAccount = null,
  ownerContract = null,
  tenantContract = null,
  application = null,
}) => {
  let ownerContacts = ownerContract?.allContacts;
  if (opportunity) {
    ownerContacts = opportunity.allContacts;
  }
  if (!ownerContacts && directAccount) {
    ownerContacts = directAccount.allContacts;
  }

  return {
    vendor: job?.vendor || null,
    billToContacts:
      invoice?.billToName === 'On Q Property Management'
        ? invoice.billToContacts
        : [],
    opportunityOwner: opportunity?.owner || null,
    ownerContacts: ownerContacts || [],
    tenantContacts:
      tenantContract?.allContacts || application?.account?.allContacts || [],
    propertyManager:
      application?.propertyManager || property?.propertyManager || null,
    assistant: property?.assistant || null,
  };
};

/**
 * Group Sms mesages by contact id
 */
const groupSmsMessages = (smsMessages = []) => {
  // sort by created date
  const sorted = orderBy(smsMessages, ['createdAt'], ['asc']);
  // group by contact id
  return sorted.reduce((memo, message) => {
    const { contactId } = message;

    if (!memo[contactId]) {
      memo[contactId] = [];
    }
    memo[contactId].push(message);

    return memo;
  }, {});
};

/**
 * Group Sms templates by group name
 */
const groupSmsTemplates = (smsTemplates = []) => {
  // group templates
  const grouped = smsTemplates.reduce((memo, template) => {
    const group = template.group || 'General';
    if (!memo[group]) {
      memo[group] = [];
    }
    memo[group].push({
      ...template,
      // for dropdown
      value: template.id,
      label: template.name,
    });
    return memo;
  }, {});

  // restructure for optiongroups
  const templates = Object.keys(grouped).map((group) => ({
    isGroup: true,
    label: group,
    options: grouped[group],
  }));

  return templates;
};

/**
 * Convert contacts into a group
 */
const convertContactsGroup = ({ label = '', contacts = [] }) => ({
  isGroup: true,
  label,
  options: contacts.map((contact) => {
    let { name } = contact;
    if (contact.phone) {
      name += ` ${contact.phone}`;
    }
    return {
      value: contact.id,
      label: name,
    };
  }),
});

/**
 * Group contacts by type
 */
const groupSmsContacts = ({
  directAccount = null,
  ownerContract = null,
  tenantContract = null,
}) => {
  // prepare contacts
  const contactGroups = [];
  // nothing to group
  if (!directAccount && !ownerContract && !tenantContract) {
    return contactGroups;
  }

  if (directAccount) {
    // get owner contacts
    const contacts = [
      directAccount.contact1,
      directAccount.contact2,
      directAccount.contact3,
    ].filter(Boolean);

    if (contacts.length > 0) {
      contactGroups.push(
        convertContactsGroup({
          label: 'Direct Account Contacts',
          contacts,
        }),
      );
    }
  }

  if (ownerContract) {
    // get owner contacts
    const contacts = [
      ownerContract.contact1,
      ownerContract.contact2,
      ownerContract.contact3,
    ].filter(Boolean);

    if (contacts.length > 0) {
      contactGroups.push(
        convertContactsGroup({
          label: 'Owner Contacts',
          contacts,
        }),
      );
    }
  }

  if (tenantContract) {
    // get tenant contacts
    const contacts = [
      tenantContract.contact1,
      tenantContract.contact2,
      tenantContract.contact3,
    ].filter(Boolean);

    if (contacts.length > 0) {
      contactGroups.push(
        convertContactsGroup({
          label: 'Tenant Contacts',
          contacts,
        }),
      );
    }
  }

  return contactGroups;
};

/**
 * Get Sms data
 */
const getSmsData = ({
  directAccount = null,
  ownerContract = null,
  tenantContract = null,
  smsMessages = [],
  smsTemplates = [],
}) => {
  // prepare contacts
  const contacts = groupSmsContacts({
    directAccount,
    ownerContract,
    tenantContract,
  });

  // take the default phone
  let firstContact = null;
  if (contacts.length > 0) {
    firstContact = contacts[0]?.options?.[0];
  }

  return {
    defaultContactId: firstContact?.value || '',
    contacts,
    messages: groupSmsMessages(smsMessages),
    templates: groupSmsTemplates(smsTemplates),
  };
};

/**
 * Convert users for activity dropdown
 */
const convertUsers = (users = []) =>
  [
    {
      value: '',
      label: '',
    },
  ].concat(
    orderBy(users, ['Name'], ['asc']).map((user) => ({
      value: user.Id || '',
      label: user.Name || '',
      roleId: user.UserRole?.Id || '',
      roleName: user.UserRole?.Name || '',
    })),
  );

/**
 * Convert record types for dropdown when edit
 */
const convertRecordTypes = ({
  recordTypes = [],
  entityType = '',
  markets = [],
}) => {
  if (entityType === EntityType.OPPORTUNITIES) {
    return [
      {
        value: '',
        label: '',
      },
    ].concat(
      markets.map((market) => ({
        value: market?.id,
        label: market?.name,
      })),
    );
  }

  return [
    {
      value: '',
      label: '',
    },
  ].concat(
    recordTypes
      .filter((type) => !['Mammoth Pro', 'Mammoth Pest'].includes(type.Name))
      .map((type) => ({
        value: type.Id,
        label: type.Name,
      })),
  );
};

/**
 * Prepare template variables for Email and SMS templates
 */
const getGeneralTemplateData = ({
  currentUser = null,
  companyInfo = null,
  marketInfo = null,
}) => {
  const {
    EMPLOYEE_NAME,
    EMPLOYEE_PHONE,
    EMPLOYEE_EMAIL,
    // company info
    COMPANY_NAME,
    COMPANY_PHONE,
    MARKET,
  } = getEmailTemplateVariableNames({
    useArea: 'communication',
    type: 'shared',
  });

  // get company
  const companyName = companyInfo?.FullName || companyInfo?.Name || '';

  // get employee
  const employeeNameValue = currentUser
    ? `${currentUser.firstName} ${currentUser.lastName}`
    : '';

  let companyPhone = marketInfo?.phone;
  if (companyInfo?.id === CompanyId.MAMMOTH) {
    companyPhone = marketInfo?.mammothServices?.phone;
  }

  return {
    // current user
    [EMPLOYEE_NAME]: employeeNameValue,
    [EMPLOYEE_PHONE]: currentUser?.phone || '',
    [EMPLOYEE_EMAIL]: currentUser?.email || '',
    // company info
    [COMPANY_NAME]: companyName,
    [COMPANY_PHONE]: companyPhone || companyInfo?.Phone || '',
    // market
    [MARKET]: marketInfo?.name || '',
  };
};

/**
 * Extend data with activities and collect template values
 */
const extendDataWithActivitiesAndCollectTemplateConstants = ({
  activities = [],
  emailTemplates = [],
  companyInfo = null,
  currentUser = null,
  convertedData = null,
  marketInfo = null,
}) => {
  // group activities
  const groupedActivities = groupActivities(activities);
  const groupedEmailTemplates = groupEmailTemplates(emailTemplates);

  // get default template data
  const templateData = getGeneralTemplateData({
    currentUser,
    companyInfo,
    marketInfo,
  });

  return [
    'owner',
    'lease',
    'inspection',
    'workOrder',
    'opportunity',
    'lead',
    'property',
    'listing',
    'locker',
    'invoice',
    'job',
    'application',
    'appointment',
  ].reduce(
    (memo, variable) => {
      const entity = convertedData?.[variable];
      if (entity) {
        const unsortedEmailTemplates = groupedEmailTemplates[variable] || [];
        // eslint-disable-next-line no-confusing-arrow
        const sortedEmailTemplates = unsortedEmailTemplates.sort((a, b) =>
          a.name > b.name ? 1 : -1,
        );
        memo[variable] = {
          ...entity,
          activities: groupedActivities[entity.id] || [],
          emailTemplates: sortedEmailTemplates,
        };

        memo.templateData = {
          ...memo.templateData,
          ...entity.templateData,
        };
      }

      return memo;
    },
    {
      templateData,
    },
  );
};

/**
 * Detect default open tab based on detectedEntityType
 */
const detectDefaultOpenTab = ({
  owner = null,
  lease = null,
  detectedEntityType = '',
  detectedEntityId = '',
}) => {
  let openTab = '';
  switch (detectedEntityType) {
    case EntityType.INSPECTIONS:
      openTab = 'inspection';
      break;

    case EntityType.WORK_ORDERS:
      openTab = 'workOrder';
      break;

    case EntityType.PROPERTIES:
      openTab = 'property';
      break;

    case EntityType.LISTINGS:
      openTab = 'listing';
      break;

    case EntityType.OPPORTUNITIES:
      openTab = 'opportunity';
      break;
    case EntityType.LEADS:
      openTab = 'lead';
      break;

    case EntityType.LOCKERS:
      openTab = 'locker';
      break;

    case EntityType.INVOICES:
      openTab = 'invoice';
      break;

    case EntityType.JOBS:
      openTab = 'job';
      break;

    case EntityType.APPOINTMENTS:
      openTab = 'appointment';
      break;

    case EntityType.CONTRACTS:
      if (owner && !lease) {
        openTab = 'owner';
      } else if (!owner && lease) {
        openTab = 'lease';
      } else if (owner && lease) {
        if (detectedEntityId === owner.id) {
          openTab = 'owner';
        } else {
          openTab = 'lease';
        }
      }
      break;

    case EntityType.APPLICATIONS:
      openTab = 'application';
      break;

    default:
  }
  return openTab;
};

/**
 * Convert and group data for the communication panel/page
 */
export const prepareCommunicationData = ({
  entity,
  // entityType and detectedEntityType can be different
  // for contracts related to the property entityType = PROPERTIES and detectedEntityType = CONTRACTS
  entityType,
  detectedEntityType,
  detectedEntityId, // original id sent with request and returned back
  activities = [],
  emailTemplates = [],
  smsTemplates = [],
  smsMessages = [],
  users = [],
  pickLists = null,
  filterOptions = null,
  recordTypes = [],
  companyInfo = null,
  emailConfig = null,
  markets = [],
}) => {
  const currentUser = Auth.getUser();

  // convert data
  const results = extendDataWithActivitiesAndCollectTemplateConstants({
    convertedData: convertEntityData({
      entity,
      entityType,
      currentUser,
      companyInfo,
      markets,
    }),
    emailTemplates,
    activities,
    companyInfo,
    currentUser,
    marketInfo: convertMarket(entity.marketInfo),
  });

  const { lease, opportunity, invoice, job, templateData } = results;

  // eslint-disable-next-line prefer-const
  let { property, locker, owner, application } = results;

  // override some values for opportunity
  if (opportunity && entityType === EntityType.OPPORTUNITIES) {
    owner = opportunity; // to pull contacts
    property = opportunity; // to pull property manager and assistant
  }

  // prepare email data from converted entities
  const emailData = getEmailData({
    job,
    invoice,
    property,
    opportunity,
    directAccount: property?.directAccount,
    ownerContract: owner,
    tenantContract: locker?.directAccount || lease,
    application,
  });

  // prepare sms data
  const smsData = getSmsData({
    directAccount: property?.directAccount,
    ownerContract: owner,
    tenantContract: lease,
    smsMessages,
    smsTemplates,
  });

  const defaultOpenTab = detectDefaultOpenTab({
    ...results,
    detectedEntityType,
    detectedEntityId,
  });

  return {
    ...results,
    defaultOpenTab,
    loadedEntityId: entity?.Id || '',
    emailData: {
      ...emailData,
      entityType,
      templateData,
      emailConfig,
    },
    smsData: {
      ...smsData,
      templateData,
    },
    pickLists,
    filterOptions,
    recordTypes: convertRecordTypes({ recordTypes, entityType, markets }),
    users: convertUsers(users),
    markets,
  };
};
