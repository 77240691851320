// import Login from 'containers/Login/Loadable';
import SsoCallback from 'containers/SsoCallback/Loadable';
import WorkOrder from 'containers/WorkOrder/Loadable';
import WorkOrders from 'containers/WorkOrders/Loadable';
import NotFound from 'containers/NotFound/Loadable';
import Forbidden from 'containers/Forbidden/Loadable';
import ThankYou from 'containers/ThankYou/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import FileManager from 'containers/FileManager/Loadable';
import FileManagerProperty from 'containers/FileManagerProperty/Loadable';
import InspectionManager from 'containers/InspectionManager/Loadable';
import Inspections from 'containers/Inspections/Loadable';
import SimpleSignup from 'containers/SimpleSignup/Loadable';
import SimpleSignupDashboard from 'containers/SimpleSignupDashboard/Loadable';
import Job from 'containers/Job/Loadable';
import JobDashboard from 'containers/JobDashboard/Loadable';
import Scheduler from 'containers/Scheduler/Loadable';
import SchedulerAuto from 'containers/SchedulerAuto/Loadable';
import SmsManager from 'containers/SmsManager/Loadable';
import DialpadSmsHistory from 'containers/DialpadSmsHistory/Loadable';
import MaintenanceRequest from 'containers/MaintenanceRequest/Loadable';
import TenantNoticeToVacate from 'containers/TenantNoticeToVacate/Loadable';
import PropertyDashboardOwner from 'containers/PropertyDashboard/OwnerDashboard/Loadable';
import PropertyDashboardEmployee from 'containers/PropertyDashboard/EmployeeDashboard/Loadable';
import PortfolioDashboard from 'containers/PortfolioDashboard/Loadable';
import Communication from 'containers/Communication/Loadable';
import CommunicationDashboard from 'containers/CommunicationDashboard/Loadable';
import ProcessAutomationDashboard from 'containers/ProcessAutomationDashboard/Loadable';
import PropertyKeyHistory from 'containers/PropertyKeyHistory/Loadable';
import HvacCampaign from 'containers/HvacCampaign/Loadable';
import HelpDesk from 'containers/HelpDesk/Loadable';
import PostOffice from 'containers/PostOffice/Loadable';
import AccountManager from 'containers/AccountManager/Loadable';
import AccountManagerDashboard from 'containers/AccountManagerDashboard/Loadable';
import LeaseRenewal from 'containers/LeaseRenewal/Loadable';
import Feedback from 'containers/Feedback/Loadable';
import LockerManager from 'containers/VisualManagerDashboard/LockerManager';
import LockboxManager from 'containers/VisualManagerDashboard/LockboxManager/Loadable';
import RelatedItems from 'containers/RelatedItems/Loadable';
import Invoice from 'containers/Invoice/Loadable';
import ProcessAutomationTenantDisposition from 'containers/ProcessAutomationV2/TenantDisposition/Loadable';
import ProcessAutomationLockerReservation from 'containers/ProcessAutomationV2/LockerReservation/Loadable';
import ProcessAutomationMonthToMonth from 'containers/ProcessAutomationV2/MonthToMonth/Loadable';
import ProcessAutomationCreateWorkOrder from 'containers/ProcessAutomationV2/CreateWorkOrder/Loadable';
import ProcessAutomationPropertyManagerChange from 'containers/ProcessAutomationV2/PropertyManagerChange/Loadable';
import ProcessAutomationManagementChange from 'containers/ProcessAutomationV2/ManagementChange/Loadable';
import ProcessAutomationScheduleInspection from 'containers/ProcessAutomationV2/ScheduleInspection/Loadable';
import ProcessAutomationHoaViolation from 'containers/ProcessAutomationV2/HoaViolation/Loadable';
import ProcessAutomationContractCancellation from 'containers/ProcessAutomationV2/ContractCancellation/Loadable';
import ProcessAutomationFiveDayNotice from 'containers/ProcessAutomationV2/FiveDayNotice/Loadable';
import ProcessAutomationFiveDayNoticeBulk from 'containers/ProcessAutomationV2/FiveDayNoticeBulk/Loadable';
import ProcessAutomationPaymentPlan from 'containers/ProcessAutomationV2/PaymentPlan/Loadable';
import TenantApplication from 'containers/TenantApplication/Loadable';
import RecurringWorkOrder from 'containers/RecurringWorkOrders/Loadable';
import EmailTemplates from 'containers/EmailTemplates/Loadable';
import RentalAnalysisDashboard from 'containers/RentalAnalysisDashboard/Loadable';
import RentalAnalysisReport from 'containers/RentalAnalysisReport/Loadable';
import PhoneUserStatusReport from 'containers/PhoneUserStatusReport/Loadable';
import PropertyDetailsAndOwnerSettings from 'containers/PropertyDetailsAndOwnerSettings/Loadable';
import WorkOrderBuilder from 'containers/WorkOrderBuilder/Loadable';
import WorkOrderProtocol from 'containers/WorkOrderProtocol/Loadable';
import VisualManagerDashboard from 'containers/VisualManagerDashboard/Loadable';
import Services from 'containers/Services/Loadable';
import Appliances from 'containers/Appliances/Loadable';
import Questionnaire from 'containers/RentalPropertyOnboarding/Loadable';
import TenantsPendingTermination from 'containers/VisualManagerDashboard/TenantsPendingTermination/Loadable';
import OwnersPendingTermination from 'containers/VisualManagerDashboard/OwnersPendingTermination/Loadable';
import ActivePropertyListings from 'containers/VisualManagerDashboard/ActivePropertyListings/Loadable';
import SimpleSignupReport from 'containers/VisualManagerDashboard/SimpleSignup/Loadable';
import PropertyTurnsReport from 'containers/VisualManagerDashboard/PropertyTurns/Loadable';
import NewOwnersReport from 'containers/VisualManagerDashboard/NewOwners/Loadable';
import LeaseRenewalsReport from 'containers/VisualManagerDashboard/LeaseRenewals/Loadable';
import LeasingReport from 'containers/VisualManagerDashboard/Leasing/Loadable';
import EarlyLeaseTerminationReport from 'containers/VisualManagerDashboard/EarlyLeaseTermination/Loadable';
import MarketingUpdatesReport from 'containers/VisualManagerDashboard/MarketingUpdates/Loadable';
import RentalListingSyndicationReport from 'containers/VisualManagerDashboard/RentalListingSyndication/Loadable';
import PropertywareAuditReport from 'containers/VisualManagerDashboard/PropertywareAudit/Loadable';
import AccountsReceivableReport from 'containers/VisualManagerDashboard/AccountsReceivable/Loadable';
import MarketingFeedbackReport from 'containers/VisualManagerDashboard/MarketingFeedback/Loadable';
import AccountsPayableReport from 'containers/VisualManagerDashboard/AccountsPayable/Loadable';
import CompanyCardReconciliationReport from 'containers/VisualManagerDashboard/CompanyCardReconciliation/Loadable';
import PriceBookReport from 'containers/VisualManagerDashboard/PriceBook/Loadable';
import DocusignDocuments from 'containers/DocusignDocuments/Loadable';
import ContractAddendumBuilder from 'containers/ContractAddendumBuilder/Loadable';
import RentalListingLeadsReport from 'containers/VisualManagerDashboard/RentalListingLeads/Loadable';
import RentalAnalysisVmReport from 'containers/VisualManagerDashboard/RentalAnalysisReport/Loadable';
import PropertyKeyTagEventsVmReport from 'containers/VisualManagerDashboard/PropertyKeyTagEvents/Loadable';
import PropertyKeyTagsVmReport from 'containers/VisualManagerDashboard/PropertyKeyTags/Loadable';
import DailyKeyTagPullReport from 'containers/VisualManagerDashboard/DailyKeyTagPull/Loadable';
import RoundRobinAssignees from 'containers/VisualManagerDashboard/RoundRobinAssignees/Loadable';
import PaymentPlan from 'containers/VisualManagerDashboard/PaymentPlan/Loadable';
import FastfieldsInspections from 'containers/VisualManagerDashboard/FastfieldsInspections/Loadable';
import DocumentProcessing from 'containers/VisualManagerDashboard/DocumentProcessing/Loadable';
import MissingTpt from 'containers/VisualManagerDashboard/MissingTpt/Loadable';
import PortalEventLog from 'containers/VisualManagerDashboard/PortalEventLog/Loadable';
import HTMLSignature from 'containers/HTMLSignature/Loadable';
import TerminateTpt from 'containers/VisualManagerDashboard/TerminateTpt/Loadable';
import OwnerProspects from 'containers/VisualManagerDashboard/OwnerProspects/Loadable';
import ProcessAutomationVacateNotice30Day from 'containers/ProcessAutomationV2/VacateNotice30Day/Loadable';
import ProcessAutomationVacateNotice10Day from 'containers/ProcessAutomationV2/VacateNotice10Day/Loadable';
import ProcessAutomationRentChangeNotice from 'containers/ProcessAutomationV2/RentChangeNotice/Loadable';
import ProcessAutomationAccessNotice from 'containers/ProcessAutomationV2/AccessNotice/Loadable';
import ProcessAutomationNewLease from 'containers/ProcessAutomationV2/NewLease/Loadable';
import ProcessAutomationMiscellaneous from 'containers/ProcessAutomationV2/Miscellaneous/Loadable';
import ProcessAutomationNoticeOfHoldover from 'containers/ProcessAutomationV2/NoticeOfHoldover/Loadable';
import ProcessAutomationCreateInspection from 'containers/ProcessAutomationV2/CreateInspection/Loadable';
import ProcessAutomationCreateInspectionNewCustomer from 'containers/ProcessAutomationV2/CreateInspectionNewCustomer/Loadable';
import ProcessAutomationCreateWorkOrderNewCustomer from 'containers/ProcessAutomationV2/CreateWorkOrderNewCustomer/Loadable';
import ProcessAutomationContractTermination from 'containers/ProcessAutomationV2/ContractTermination/Loadable';
import ProcessAutomationKeyCheckIn from 'containers/ProcessAutomationV2/KeyCheckIn/Loadable';
import ProcessAutomationKeyCheckOut from 'containers/ProcessAutomationV2/KeyCheckOut/Loadable';
import PrintLabel from 'containers/PrintLabel/Loadable';
import SimpleSolutions from 'containers/SimpleSolutions/Loadable';
import ContractCalculations from 'containers/ContractCalculations/Loadable';
import SmsManagerContact from 'containers/SmsManagerContact/Loadable';
import { constants as ReportTypes } from 'enums/reportType.enum';
import { EMPLOYEE, PHONE_SUPPORT, CUSTOMER, VENDOR } from 'helpers/userRoles';
import {
  constants as AutomationType,
  permissions as AutomationPermissions,
} from 'enums/processAutomationType';
import PropertyListingsFindRental from 'containers/PropertyListingsFindRental/Loadable';
import PropertyListingsWidget from 'containers/PropertyListingsWidget/Loadable';
import PropertyListingsWidgetListing from 'containers/PropertyListingsWidgetListing/Loadable';
import WorkOrdersV2 from 'containers/VisualManagerDashboard/WorkOrders/Loadable';
import InspectionsV2 from 'containers/VisualManagerDashboard/Inspections/Loadable';
import Vendors from 'containers/VisualManagerDashboard/Vendors/Loadable';

const employeeRoles = [EMPLOYEE];

export default [
  // private routes
  {
    path: '/work-orders',
    component: WorkOrders,
    roles: employeeRoles,
    permissions: [
      'View_Work_Orders__c',
      'Create_Work_Orders__c',
      'Edit_Work_Orders__c',
    ],
  },
  {
    path: '/work-orders/:id',
    component: WorkOrder,
  },
  {
    path: '/work-orders/:workOrderId/builder',
    component: WorkOrderBuilder,
    roles: employeeRoles,
    permissions: ['Edit_Work_Orders__c'],
  },
  {
    path: '/work-orders/:workOrderId/protocol',
    component: WorkOrderProtocol,
    roles: employeeRoles,
    permissions: ['Edit_Work_Orders__c'],
  },
  // -----------------------------------------
  {
    path: '/inspections',
    component: Inspections,
    roles: employeeRoles,
  },
  {
    path: '/inspections/:id',
    component: InspectionManager,
  },
  // -----------------------------------------
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/file-manager/:sobjectId',
    component: FileManager,
  },
  {
    path: '/file-manager/:sobjectId/full',
    component: FileManagerProperty,
  },
  // -----------------------------------------
  {
    path: '/simple-signup',
    component: SimpleSignupDashboard,
    roles: employeeRoles,
  },
  {
    path: '/simple-signup/:id',
    component: SimpleSignup,
    roles: employeeRoles,
  },
  // -----------------------------------------
  {
    path: '/job-dashboard/0014100000akkGQAAY/:entityType', // Mammoth
    component: JobDashboard,
    roles: employeeRoles,
    permissions: [
      'Dispatch_Manager__c',
      'Dispatch_All_Jobs__c',
      'Dispatch_Superintendent__c',
    ],
    params: {
      vendorId: '0014100000akkGQAAY',
    },
  },
  {
    path: '/job-dashboard/0014100000akkGQAAY', // Mammoth
    component: JobDashboard,
    roles: employeeRoles,
    permissions: [
      'Dispatch_Manager__c',
      'Dispatch_All_Jobs__c',
      'Dispatch_Superintendent__c',
    ],
    params: {
      vendorId: '0014100000akkGQAAY',
    },
  },
  {
    path: '/tech-dashboard/:technicianId',
    component: JobDashboard,
    roles: employeeRoles,
    permissions: ['Dispatch_Access__c'],
  },
  {
    path: '/tech-dashboard',
    component: JobDashboard,
    roles: employeeRoles,
    permissions: ['Dispatch_Access__c'],
  },
  // -----------------------------------------
  {
    path: '/dispatch/:id',
    component: Scheduler,
    roles: employeeRoles,
    permissions: ['Dispatch_Access__c:Work Orders'],
  },
  {
    path: '/dispatch/:id/inspections',
    component: Scheduler,
    roles: employeeRoles,
    permissions: ['Dispatch_Access__c:Inspections'],
    params: {
      entityType: 'inspections',
    },
  },
  {
    path: '/dispatch/:id/inspections/autoschedule',
    component: SchedulerAuto,
    roles: employeeRoles,
    permissions: ['Dispatch_Auto_Scheduler__c'],
    params: {
      entityType: 'inspections',
    },
  },
  // -----------------------------------------
  {
    path: '/sms-manager/:companyId/:source',
    component: SmsManager,
    roles: employeeRoles,
  },
  {
    path: '/sms-manager/:companyId',
    component: SmsManager,
    roles: employeeRoles,
  },
  // -----------------------------------------
  {
    path: '/dialpad-sms-history/:phone',
    component: DialpadSmsHistory,
    roles: employeeRoles,
  },
  // -----------------------------------------
  {
    path: '/properties/:id',
    component: PropertyDashboardOwner,
  },
  {
    path: '/properties/:id/employee',
    component: PropertyDashboardEmployee,
    roles: employeeRoles,
  },
  {
    path: '/portfolio/:id',
    component: PortfolioDashboard,
  },
  {
    path: '/portfolio',
    component: PortfolioDashboard,
  },
  // -----------------------------------------
  {
    path: '/communication',
    component: CommunicationDashboard,
    roles: employeeRoles,
  },
  {
    path: '/communication/:id',
    component: Communication,
    roles: employeeRoles,
  },
  // -----------------------------------------
  {
    path: '/services/:propertyId',
    component: Services,
    roles: [...employeeRoles, CUSTOMER, PHONE_SUPPORT],
  },
  // -----------------------------------------
  {
    path: '/appliances/:propertyId',
    component: Appliances,
    roles: employeeRoles,
  },
  {
    path: '/simple-signup/:leadOrOpportunityId/questionnaire',
    component: Questionnaire,
    isPublic: true,
  },
  // -----------------------------------------
  {
    path: '/process-automation/:entityId',
    component: ProcessAutomationDashboard,
    roles: employeeRoles,
    permissions: ['Process_Automation__c'],
  },
  {
    path: '/process-automation',
    component: ProcessAutomationDashboard,
    roles: [...employeeRoles, PHONE_SUPPORT],
    permissions: ['Process_Automation__c', 'Create_Work_Orders__c'],
  },
  {
    path: `/process-automation/${AutomationType.TENANT_DISPOSITION}/:entityId`,
    component: ProcessAutomationTenantDisposition,
    roles: employeeRoles,
    permissions: AutomationPermissions[AutomationType.TENANT_DISPOSITION],
    params: {
      automationType: AutomationType.TENANT_DISPOSITION,
    },
  },
  {
    path: `/process-automation/${AutomationType.LOCKER_RESERVATION}/:entityId`,
    component: ProcessAutomationLockerReservation,
    roles: employeeRoles,
    permissions: AutomationPermissions[AutomationType.LOCKER_RESERVATION],
    params: {
      automationType: AutomationType.LOCKER_RESERVATION,
    },
  },
  {
    path: `/process-automation/${AutomationType.MONTH_TO_MONTH}/:entityId`,
    component: ProcessAutomationMonthToMonth,
    roles: employeeRoles,
    permissions: AutomationPermissions[AutomationType.MONTH_TO_MONTH],
    params: {
      automationType: AutomationType.MONTH_TO_MONTH,
    },
  },
  {
    path: `/process-automation/${AutomationType.CREATE_WORK_ORDER_NEW_CUSTOMER}/:entityId`,
    component: ProcessAutomationCreateWorkOrderNewCustomer,
    roles: employeeRoles,
    permissions:
      AutomationPermissions[AutomationType.CREATE_WORK_ORDER_NEW_CUSTOMER],
    params: {
      automationType: AutomationType.CREATE_WORK_ORDER_NEW_CUSTOMER,
    },
  },
  {
    path: `/process-automation/${AutomationType.CREATE_INSPECTION}/:entityId`,
    component: ProcessAutomationCreateInspection,
    roles: employeeRoles,
    permissions: AutomationPermissions[AutomationType.CREATE_INSPECTION],
    params: {
      automationType: AutomationType.CREATE_INSPECTION,
    },
  },
  {
    path: `/process-automation/${AutomationType.CREATE_INSPECTION_NEW_CUSTOMER}/:entityId`,
    component: ProcessAutomationCreateInspectionNewCustomer,
    roles: employeeRoles,
    permissions:
      AutomationPermissions[AutomationType.CREATE_INSPECTION_NEW_CUSTOMER],
    params: {
      automationType: AutomationType.CREATE_INSPECTION_NEW_CUSTOMER,
    },
  },
  {
    path: `/process-automation/${AutomationType.CREATE_WORK_ORDER}/:entityId`,
    component: ProcessAutomationCreateWorkOrder,
    permissions: AutomationPermissions[AutomationType.CREATE_WORK_ORDER],
    // Can be accessed by Employees and special employees from the Phone Support
    roles: [...employeeRoles, PHONE_SUPPORT],
    params: {
      automationType: AutomationType.CREATE_WORK_ORDER,
    },
  },
  {
    path: `/process-automation/${AutomationType.PROPERTY_MANAGER_CHANGE}/:entityId`,
    component: ProcessAutomationPropertyManagerChange,
    permissions: AutomationPermissions[AutomationType.PROPERTY_MANAGER_CHANGE],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.PROPERTY_MANAGER_CHANGE,
    },
  },
  {
    path: `/process-automation/${AutomationType.MANAGEMENT_CHANGE}/:entityId`,
    component: ProcessAutomationManagementChange,
    permissions: AutomationPermissions[AutomationType.MANAGEMENT_CHANGE],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.MANAGEMENT_CHANGE,
    },
  },
  {
    path: `/process-automation/${AutomationType.SCHEDULE_INSPECTION}/:entityId`,
    component: ProcessAutomationScheduleInspection,
    permissions: AutomationPermissions[AutomationType.SCHEDULE_INSPECTION],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.SCHEDULE_INSPECTION,
    },
  },
  {
    path: `/process-automation/${AutomationType.HOA_VIOLATION}/:entityId`,
    component: ProcessAutomationHoaViolation,
    permissions: AutomationPermissions[AutomationType.HOA_VIOLATION],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.HOA_VIOLATION,
    },
  },
  {
    path: `/process-automation/${AutomationType.CONTRACT_CANCELLATION}/:entityId`,
    component: ProcessAutomationContractCancellation,
    permissions: AutomationPermissions[AutomationType.CONTRACT_CANCELLATION],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.CONTRACT_CANCELLATION,
    },
  },
  {
    path: `/process-automation/${AutomationType.FIVE_DAY_NOTICE}/:entityId`,
    component: ProcessAutomationFiveDayNotice,
    permissions: AutomationPermissions[AutomationType.FIVE_DAY_NOTICE],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.FIVE_DAY_NOTICE,
    },
  },
  {
    path: `/process-automation/${AutomationType.FIVE_DAY_NOTICE_BULK}`,
    component: ProcessAutomationFiveDayNoticeBulk,
    permissions: AutomationPermissions[AutomationType.FIVE_DAY_NOTICE_BULK],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.FIVE_DAY_NOTICE_BULK,
    },
  },
  {
    path: `/process-automation/${AutomationType.PAYMENT_PLAN}/:entityId`,
    component: ProcessAutomationPaymentPlan,
    permissions: AutomationPermissions[AutomationType.PAYMENT_PLAN],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.PAYMENT_PLAN,
    },
  },
  {
    path: `/process-automation/${AutomationType.HOA_VIOLATION}/:entityId`,
    component: ProcessAutomationHoaViolation,
    permissions: AutomationPermissions[AutomationType.HOA_VIOLATION],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.HOA_VIOLATION,
    },
  },
  {
    path: `/process-automation/${AutomationType.VACATE_NOTICE_30_DAY}/:entityId`,
    component: ProcessAutomationVacateNotice30Day,
    permissions: AutomationPermissions[AutomationType.VACATE_NOTICE_30_DAY],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.VACATE_NOTICE_30_DAY,
    },
  },
  {
    path: `/process-automation/${AutomationType.VACATE_NOTICE_10_DAY}/:entityId`,
    component: ProcessAutomationVacateNotice10Day,
    permissions: AutomationPermissions[AutomationType.VACATE_NOTICE_10_DAY],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.VACATE_NOTICE_10_DAY,
    },
  },
  {
    path: `/process-automation/${AutomationType.RENT_CHANGE_NOTICE}/:entityId`,
    component: ProcessAutomationRentChangeNotice,
    permissions: AutomationPermissions[AutomationType.RENT_CHANGE_NOTICE],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.RENT_CHANGE_NOTICE,
    },
  },
  {
    path: `/process-automation/${AutomationType.ACCESS_NOTICE}/:entityId`,
    component: ProcessAutomationAccessNotice,
    permissions: AutomationPermissions[AutomationType.ACCESS_NOTICE],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.ACCESS_NOTICE,
    },
  },
  {
    path: `/process-automation/${AutomationType.NEW_LEASE}/:entityId`,
    component: ProcessAutomationNewLease,
    permissions: AutomationPermissions[AutomationType.NEW_LEASE],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.NEW_LEASE,
    },
  },
  {
    path: `/process-automation/${AutomationType.MISCELLANEOUS}/:entityId`,
    component: ProcessAutomationMiscellaneous,
    permissions: AutomationPermissions[AutomationType.MISCELLANEOUS],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.MISCELLANEOUS,
    },
  },
  {
    path: `/process-automation/${AutomationType.NOTICE_OF_HOLDOVER}/:entityId`,
    component: ProcessAutomationNoticeOfHoldover,
    permissions: AutomationPermissions[AutomationType.NOTICE_OF_HOLDOVER],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.NOTICE_OF_HOLDOVER,
    },
  },
  {
    path: `/process-automation/${AutomationType.CONTRACT_TERMINATION}/:entityId`,
    component: ProcessAutomationContractTermination,
    permissions: AutomationPermissions[AutomationType.CONTRACT_TERMINATION],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.CONTRACT_TERMINATION,
    },
  },
  {
    path: `/process-automation/${AutomationType.KEY_CHECK_IN}/:entityId`,
    component: ProcessAutomationKeyCheckIn,
    permissions: AutomationPermissions[AutomationType.KEY_CHECK_IN],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.KEY_CHECK_IN,
    },
  },
  {
    path: `/process-automation/${AutomationType.KEY_CHECK_OUT}/:entityId`,
    component: ProcessAutomationKeyCheckOut,
    permissions: AutomationPermissions[AutomationType.KEY_CHECK_OUT],
    roles: employeeRoles,
    params: {
      automationType: AutomationType.KEY_CHECK_OUT,
    },
  },
  // -----------------------------------------
  {
    path: '/account-manager',
    component: AccountManagerDashboard,
    roles: employeeRoles,
  },
  {
    path: '/account-manager/new/012410000006s8aAAA', // new vendor
    component: AccountManager,
    roles: employeeRoles,
    permissions: ['Edit_Vendors__c'],
    params: {
      accountId: 'new',
      accountTypeId: '012410000006s8aAAA',
    },
  },
  {
    path: '/account-manager/:accountId/:accountTypeId',
    component: AccountManager,
    roles: employeeRoles,
  },
  {
    path: '/account-manager/:accountId',
    component: AccountManager,
    roles: employeeRoles,
  },
  // -----------------------------------------
  {
    path: '/visual-manager',
    component: VisualManagerDashboard,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c'],
  },
  {
    path: `/visual-manager/${ReportTypes.LOCKBOX_MANAGER}`,
    component: LockboxManager,
    roles: employeeRoles,
    permissions: ['Lockbox_Manager__c'],
    params: {
      reportType: ReportTypes.LOCKBOX_MANAGER,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.LOCKER_MANAGER}`,
    component: LockerManager,
    roles: employeeRoles,
    permissions: ['Locker_Manager__c'],
    params: {
      reportType: ReportTypes.LOCKER_MANAGER,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.LEASE_RENEWALS}`,
    component: LeaseRenewalsReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.LEASE_RENEWALS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.LEASING}`,
    component: LeasingReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Leasing'],
    params: {
      reportType: ReportTypes.LEASING,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.EARLY_LEASE_TERMINATION}`,
    component: EarlyLeaseTerminationReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.EARLY_LEASE_TERMINATION,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.PROPERTY_TURNS}`,
    component: PropertyTurnsReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.PROPERTY_TURNS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.NEW_OWNERS}`,
    component: NewOwnersReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.NEW_OWNERS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.OWNERS_PENDING_TERMINATION}`,
    component: OwnersPendingTermination,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.OWNERS_PENDING_TERMINATION,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.TENANTS_PENDING_TERMINATION}`,
    component: TenantsPendingTermination,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.TENANTS_PENDING_TERMINATION,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.MARKETING_UPDATES}`,
    component: MarketingUpdatesReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.MARKETING_UPDATES,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.PROPERTYWARE_AUDIT}`,
    component: PropertywareAuditReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.PROPERTYWARE_AUDIT,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.SIMPLE_SIGNUP}`,
    component: SimpleSignupReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Simple Signup'],
    params: {
      reportType: ReportTypes.SIMPLE_SIGNUP,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.ACTIVE_PROPERTY_LISTINGS}`,
    component: ActivePropertyListings,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Listing Reports'],
    params: {
      reportType: ReportTypes.ACTIVE_PROPERTY_LISTINGS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.RENTAL_LISTING_SYNDICATION}`,
    component: RentalListingSyndicationReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Listing Reports'],
    params: {
      reportType: ReportTypes.RENTAL_LISTING_SYNDICATION,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.ACCOUNTS_RECEIVABLE_ONQ}`,
    component: AccountsReceivableReport,
    roles: employeeRoles,
    permissions: ['Accounts_Receivable__c'],
    params: {
      reportType: ReportTypes.ACCOUNTS_RECEIVABLE_ONQ,
      billingMethod: 'On Q Ledger',
    },
  },
  {
    path: `/visual-manager/${ReportTypes.ACCOUNTS_RECEIVABLE_MAMMOTH}`,
    component: AccountsReceivableReport,
    roles: employeeRoles,
    permissions: ['Accounts_Receivable__c'],
    params: {
      reportType: ReportTypes.ACCOUNTS_RECEIVABLE_MAMMOTH,
      billingMethod: 'Mammoth Direct',
    },
  },
  {
    path: `/visual-manager/${ReportTypes.MARKETING_FEEDBACK}`,
    component: MarketingFeedbackReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Listing Reports'],
    params: {
      reportType: ReportTypes.MARKETING_FEEDBACK,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.ACCOUNTS_PAYABLE_ONQ}`,
    component: AccountsPayableReport,
    roles: employeeRoles,
    permissions: ['Accounts_Payable__c'],
    params: {
      reportType: ReportTypes.ACCOUNTS_PAYABLE_ONQ,
      recordTypeId: '0121K000001AcIqQAK', // On Q Expense
    },
  },
  {
    path: `/visual-manager/${ReportTypes.ACCOUNTS_PAYABLE_MAMMOTH}`,
    component: AccountsPayableReport,
    roles: employeeRoles,
    permissions: ['Accounts_Payable__c'],
    params: {
      reportType: ReportTypes.ACCOUNTS_PAYABLE_MAMMOTH,
      recordTypeId: '0121K000001AcIlQAK', // Mammoth Expense
    },
  },
  {
    path: `/visual-manager/${ReportTypes.COMPANY_CARD_RECONCILIATION}`,
    component: CompanyCardReconciliationReport,
    roles: employeeRoles,
    permissions: ['Accounts_Payable__c'],
    params: {
      reportType: ReportTypes.COMPANY_CARD_RECONCILIATION,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.RENTAL_MARKET_ANALYSIS}`,
    component: RentalAnalysisVmReport,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.RENTAL_MARKET_ANALYSIS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.WORK_ORDERS_V2}`,
    component: WorkOrdersV2,
    roles: employeeRoles,
    permissions: [
      'View_Work_Orders__c',
      'Create_Work_Orders__c',
      'Edit_Work_Orders__c',
    ],
  },
  {
    path: `/visual-manager/${ReportTypes.INSPECTIONS_V2}`,
    component: InspectionsV2,
    roles: employeeRoles,
  },
  // Also exists on /work-orders route.
  {
    path: `/visual-manager/${ReportTypes.WORK_ORDERS}`,
    component: WorkOrders,
    roles: employeeRoles,
    permissions: [
      'View_Work_Orders__c',
      'Create_Work_Orders__c',
      'Edit_Work_Orders__c',
    ],
  },
  // Also exists on /inspections route
  {
    path: `/visual-manager/${ReportTypes.INSPECTIONS}`,
    component: Inspections,
    roles: employeeRoles,
  },
  // Also exists on /post-office route
  {
    path: `/visual-manager/${ReportTypes.POST_OFFICE}`,
    component: PostOffice,
    roles: employeeRoles,
    permissions: ['Process_Automation__c'],
  },
  // Also exists on /price-book route
  {
    path: `/visual-manager/${ReportTypes.PRICE_BOOK}`,
    component: PriceBookReport,
    roles: employeeRoles,
    permissions: ['View_Pricebooks__c', 'Edit_Pricebooks__c'],
  },
  {
    path: `/visual-manager/${ReportTypes.RENTAL_LISTING_LEADS}`,
    component: RentalListingLeadsReport,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Listing Reports'],
    params: {
      reportType: ReportTypes.RENTAL_LISTING_LEADS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.PROPERTY_KEY_TAG_EVENTS}`,
    component: PropertyKeyTagEventsVmReport,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.PROPERTY_KEY_TAG_EVENTS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.PROPERTY_KEY_TAG_EVENTS}/:propertyId`,
    component: PropertyKeyTagEventsVmReport,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.PROPERTY_KEY_TAG_EVENTS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.PROPERTY_KEY_TAGS}`,
    component: PropertyKeyTagsVmReport,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.PROPERTY_KEY_TAGS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.DAILY_KEY_TAG_PULL}`,
    component: DailyKeyTagPullReport,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.DAILY_KEY_TAG_PULL,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.ROUND_ROBIN_ASSIGNEES}`,
    component: RoundRobinAssignees,
    permissions: ['Dev_Tools__c'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.ROUND_ROBIN_ASSIGNEES,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.PAYMENT_PLAN}`,
    component: PaymentPlan,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.PAYMENT_PLAN,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.FASTFIELDS_INSPECTIONS}`,
    component: FastfieldsInspections,
    permissions: ['Inspection_Processing__c'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.FASTFIELDS_INSPECTIONS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.DOCUMENT_PROCESSING}`,
    component: DocumentProcessing,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.DOCUMENT_PROCESSING,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.MISSING_TPT}`,
    component: MissingTpt,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.MISSING_TPT,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.TERMINATE_TPT}`,
    component: TerminateTpt,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.TERMINATE_TPT,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.OWNER_PROSPECTS}`,
    component: OwnerProspects,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.OWNER_PROSPECTS,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.PORTAL_EVENT_LOG}/:searchEntity?`,
    component: PortalEventLog,
    permissions: ['Dev_Tools__c'],
    roles: employeeRoles,
    params: {
      reportType: ReportTypes.PORTAL_EVENT_LOG,
    },
  },
  {
    path: `/visual-manager/${ReportTypes.VENDORS}`,
    component: Vendors,
    roles: employeeRoles,
    permissions: ['Visual_Manager__c:Property Manager Reports'],
    params: {
      reportType: ReportTypes.VENDORS,
    },
  },
  // -----------------------------------------
  {
    path: '/property-key-history/:propertyId',
    component: PropertyKeyHistory,
    roles: employeeRoles,
  },
  {
    path: '/post-office',
    component: PostOffice,
    roles: employeeRoles,
    permissions: ['Process_Automation__c'],
  },
  {
    path: '/help-desk',
    component: HelpDesk,
    roles: employeeRoles,
  },
  {
    path: '/lease-renewals/:contractAddendumId',
    component: LeaseRenewal,
    isPublic: true,
  },
  {
    path: '/feedback/:feedbackId',
    component: Feedback,
    isPublic: true,
  },
  {
    path: '/price-book',
    component: PriceBookReport,
    roles: employeeRoles,
    permissions: ['View_Pricebooks__c', 'Edit_Pricebooks__c'],
  },
  {
    path: '/related-items/:entityId',
    component: RelatedItems,
    roles: employeeRoles,
  },
  {
    path: '/email-templates',
    component: EmailTemplates,
    roles: employeeRoles,
    permissions: ['Dev_Tools__c'],
  },
  {
    path: '/print-label/:propertyId?',
    component: PrintLabel,
    roles: employeeRoles,
  },
  {
    path: '/invoices/:invoiceId',
    component: Invoice,
  },
  {
    path: '/rental-analysis-report/:reportIdHistorical/historical',
    component: RentalAnalysisReport,
    roles: employeeRoles,
  },
  {
    path: '/properties/:propertyId/details-and-owner-settings',
    component: PropertyDetailsAndOwnerSettings,
    roles: [...employeeRoles, PHONE_SUPPORT],
  },
  {
    path: '/docusign/opportunities/:opportunityId/documents',
    component: DocusignDocuments,
    roles: employeeRoles,
  },
  {
    path: '/docusign/addendums/:addendumId/documents',
    component: DocusignDocuments,
    roles: employeeRoles,
  },
  {
    path: '/docusign/envelopes/:envelopeId/documents',
    component: DocusignDocuments,
    roles: employeeRoles,
  },
  {
    path: '/contract-addendum/:addendumId',
    component: ContractAddendumBuilder,
    roles: employeeRoles,
  },
  {
    path: '/html-signature',
    component: HTMLSignature,
    roles: employeeRoles,
  },
  // Recurring Work Order
  {
    path: '/recurring-work-orders/:propertyId',
    component: RecurringWorkOrder,
    roles: employeeRoles,
  },
  {
    path: '/simple-solutions/:contractId',
    component: SimpleSolutions,
    roles: [...employeeRoles, CUSTOMER],
  },
  {
    path: '/contract-calculations/:contractId',
    component: ContractCalculations,
    roles: employeeRoles,
  },
  {
    path: '/jobs/:id',
    component: Job,
    roles: [...employeeRoles, VENDOR],
  },
  {
    path: '/jobs/:id/appointments/:appointmentId',
    component: Job,
    roles: [...employeeRoles, VENDOR],
  },
  {
    path: '/job-dashboard/:vendorId/:entityType',
    component: JobDashboard,
    roles: [...employeeRoles, VENDOR],
  },
  {
    path: '/job-dashboard/:vendorId',
    component: JobDashboard,
    roles: [...employeeRoles, VENDOR],
  },
  {
    path: '/sms-manager/companies/:companyId/contacts/:contactId',
    component: SmsManagerContact,
    roles: [...employeeRoles, VENDOR],
  },
  // --------------------------
  // public routes
  {
    path: '/sso-callback',
    component: SsoCallback,
    isPublic: true,
  },
  {
    path: '/thank-you',
    component: ThankYou,
    isPublic: true,
  },
  {
    path: '/maintenance-request',
    component: MaintenanceRequest,
    isPublic: true,
  },
  {
    path: '/notice-to-vacate',
    component: TenantNoticeToVacate,
    isPublic: true,
  },
  {
    path: '/hvac/:campaignId',
    component: HvacCampaign,
    isPublic: true,
  },
  {
    path: '/tenant-application/:propertyListingId',
    component: TenantApplication,
    isPublic: true,
  },
  {
    path: '/rental-analysis-report',
    component: RentalAnalysisDashboard,
    isPublic: true,
  },
  {
    path: '/rental-analysis-report/:reportId',
    component: RentalAnalysisReport,
    isPublic: true,
  },
  {
    path: '/reports/phone-user-status',
    component: PhoneUserStatusReport,
    isPublic: true,
  },
  {
    path: '/property-listings/:market/widget',
    component: PropertyListingsWidget,
    isPublic: true,
  },
  {
    path: '/property-listings/find-rental',
    component: PropertyListingsFindRental,
    isPublic: true,
  },
  {
    path: '/property-listings/:listingId',
    component: PropertyListingsWidgetListing,
    isPublic: true,
  },
  // --------------------------
  // Do not put new routes below these 2 default/catch routes
  {
    path: '/forbidden',
    component: Forbidden,
    isPublic: true,
  },
  {
    path: '*',
    component: NotFound,
    isPublic: true,
  },
];
