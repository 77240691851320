import React, { useCallback, useEffect } from 'react';
import { object, func, bool, array, string } from 'prop-types';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import LoadingIndicator from 'components/LoadingIndicator';
import CustomDrawer from 'components/CustomDrawer';
import { titles } from 'enums/reportType.enum';
import { useMergeState } from 'helpers/state';
import PageWrapper from 'containers/PageWrapper';
import HeaderActions from '../HeaderActions';

const StyledDropdownsRootBox = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
  },
}));

const MainLayout = ({
  isLoading: isCompanyLoading = false,
  isUsersLoading = false,
  isLoadingVMData = false,
  propertyManagers = [],
  primaryContent,
  reportType = '',
  vmData = null,
  isSkipLoadUsers = false,
  isSkipFirstDataLoad = false,
  isRefreshButtonHidden = false,
  isFailedFetch = false,
  isLoadMarkets = false,
  error = '',
  successMessage = '',
  loadUsers,
  loadVMData,
  onRefreshReport: onRefreshReportProp,
  clearVMData,
  loadMarkets,
}) => {
  const [state, setState] = useMergeState({
    iframeLink: '',
    propertyManager: '',
  });

  useEffect(() => {
    if (!isSkipFirstDataLoad) {
      loadVMData({ propertyManager: '', reportType });
    }

    if (isLoadMarkets) {
      loadMarkets();
    }

    if (propertyManagers?.length <= 1 && !isSkipLoadUsers) {
      loadUsers({
        role: 'Property Manager, Director Property Management, Sales Agent',
      });
    }

    window.addEventListener('message', onIframeMessage);

    return () => {
      clearVMData();

      window.removeEventListener('message', onIframeMessage);
    };
  }, []);

  const onIframeMessage = (e) => {
    const { source, action } = e.data || {};

    if (source === 'communicationPanel' && action === 'savedData') {
      loadVMData({ propertyManager: state.propertyManager, reportType });
    }
  };

  const onRefreshReport = useCallback(
    ({ propertyManager, reportType: reportTypeParam }) => {
      if (onRefreshReportProp) {
        onRefreshReportProp({ propertyManager, reportType: reportTypeParam });
      } else {
        loadVMData({ propertyManager, reportType: reportTypeParam });
      }
    },
    [onRefreshReportProp],
  );

  const onOpenSidebarIframe = (iframeLink) => setState({ iframeLink });

  const onCloseSidebarIframe = useCallback(
    () => setState({ iframeLink: '' }),
    [],
  );

  const isLoading = isCompanyLoading || isUsersLoading || isLoadingVMData;

  const primaryActions = {
    isLoading,
    propertyManager: state.propertyManager,
    onOpenSidebarIframe,
  };

  return (
    <PageWrapper
      isFailedFetch={isFailedFetch}
      error={error}
      successMessage={successMessage}
      isSkipLoadCompanyInfo
      title={titles[reportType]}
      isLoading={isCompanyLoading}
      isVisibleFooter={false}
    >
      <Box sx={{ p: 2 }}>
        <StyledDropdownsRootBox>
          <HeaderActions
            isLoading={isLoading}
            reportType={reportType}
            isRefreshButtonHidden={isRefreshButtonHidden}
            isSkipLoadUsers={isSkipLoadUsers}
            propertyManager={state.propertyManager}
            propertyManagers={propertyManagers}
            onRefreshReport={onRefreshReport}
          />
        </StyledDropdownsRootBox>

        {!vmData && !isSkipFirstDataLoad ? (
          <LoadingIndicator />
        ) : (
          primaryContent(primaryActions)
        )}
      </Box>

      <CustomDrawer isOpen={!!state.iframeLink} onClose={onCloseSidebarIframe}>
        {!!state.iframeLink && (
          <iframe
            title="Sidebar Iframe"
            frameBorder="0"
            src={state.iframeLink}
            width="100%"
            height="99%"
          />
        )}
      </CustomDrawer>
    </PageWrapper>
  );
};

MainLayout.propTypes = {
  isUsersLoading: bool,
  isSkipLoadUsers: bool,
  loadUsers: func,
  propertyManagers: array,
  isLoadingVMData: bool,
  isSkipFirstDataLoad: bool,
  loadVMData: func,
  vmData: object,
  clearVMData: func.isRequired,
  isLoading: bool,
  isRefreshButtonHidden: bool,
  isLoadMarkets: bool,
  successMessage: string,
  reportType: string,
  onRefreshReport: func,
  primaryContent: func.isRequired,
  loadMarkets: func,
  isFailedFetch: bool,
  error: string,
};

export default MainLayout;
