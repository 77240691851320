import React from 'react';
import { Link } from 'react-router-dom';
import Auth from 'helpers/auth';
import vmlogo from 'images/vmlogo.png';
import { constants as AutomationType } from 'enums/processAutomationType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { constants as ReportTypes } from 'enums/reportType.enum';
import {
  faHome,
  faUser,
  faRetweet,
  faSign,
  faUserRobot,
  faMailbox,
  faLockAlt,
  faTools,
  faFolderPlus,
  faListAlt,
  faRoute,
  faUserHardHat,
  faTags,
  faCameraPolaroid,
  faCogs,
  faHandshake,
  faFileCheck,
  faFileSignature,
  faAbacus,
  faMoneyCheckEditAlt,
  faHandHoldingUsd,
  faQuestionCircle,
  faUserShield,
  faMailBulk,
  faChartLine,
  faPhoneOffice,
  faMoneyBillTransfer,
  faPeoplePulling,
  faHouseLock,
  faPrint,
  faFilePdf,
  faFile,
} from '@fortawesome/pro-duotone-svg-icons';

// https://stackoverflow.com/a/53336754
const imageStyle = {
  filter:
    'invert(51%) sepia(6%) saturate(846%) hue-rotate(172deg) brightness(90%) contrast(88%)',
};

const externalLink = {
  label: '',
  href: '',
  component: 'a',
  target: '_self',
  rel: 'noreferrer noopener',
  visible: true,
};

const internalLink = {
  label: '',
  to: '',
  component: Link,
  target: '_self',
  visible: true,
};

const useMenuItems = () => {
  const menuItems = React.useMemo(() => {
    const isAllowedProcessAutomation = Auth.isHavePermission(
      'Process_Automation__c',
    );

    const isAllowedTemplates = Auth.isHavePermission([
      'Sign_On__c:Owner Onboarding',
      'Sign_On__c:Property Manager',
      'Sign_On__c:Leasing',
      'Sign_On__c:Admin',
    ]);

    const items = [
      {
        label: 'Property Management',
        icon: <FontAwesomeIcon icon={faHome} />,
        visible: true,
        items: [
          {
            ...internalLink,
            label: 'Account Manager',
            to: '/account-manager',
            icon: <FontAwesomeIcon icon={faUser} />,
            visible:
              Auth.isHavePermission('View_Vendors__c') ||
              Auth.isHavePermission('Edit_Vendors__c'),
          },
          {
            ...internalLink,
            label: 'Visual Manager',
            to: '/visual-manager',
            icon: (
              <img
                src={vmlogo}
                alt="Visual Manager"
                style={{
                  ...imageStyle,
                  width: 22,
                }}
              />
            ),
          },
          {
            ...internalLink,
            label: 'Lease Renewals',
            to: `/visual-manager/${ReportTypes.LEASE_RENEWALS}`,
            icon: <FontAwesomeIcon icon={faRetweet} />,
            visible: Auth.isHavePermission(
              'Visual_Manager__c:Property Manager Reports',
            ),
          },
          {
            ...internalLink,
            label: 'Marketing Updates',
            to: `/visual-manager/${ReportTypes.MARKETING_UPDATES}`,
            icon: <FontAwesomeIcon icon={faSign} />,
            visible: Auth.isHavePermission(
              'Visual_Manager__c:Property Manager Reports',
            ),
          },
          {
            ...internalLink,
            label: 'Process Automation',
            to: '/process-automation',
            icon: <FontAwesomeIcon icon={faUserRobot} />,
            visible: isAllowedProcessAutomation,
          },
          {
            ...internalLink,
            label: 'Post Office',
            to: '/post-office',
            icon: <FontAwesomeIcon icon={faMailbox} />,
            visible: isAllowedProcessAutomation,
          },
          {
            ...internalLink,
            label: 'Locker Manager',
            to: `/visual-manager/${ReportTypes.LOCKER_MANAGER}`,
            icon: <FontAwesomeIcon icon={faLockAlt} />,
            visible: Auth.isHavePermission('Locker_Manager__c'),
          },
          {
            ...internalLink,
            label: 'Lockbox Manager',
            to: `/visual-manager/${ReportTypes.LOCKBOX_MANAGER}`,
            icon: <FontAwesomeIcon icon={faHouseLock} />,
            visible: Auth.isHavePermission('Lockbox_Manager__c'),
          },
          {
            ...internalLink,
            label: 'Print Label',
            to: '/print-label',
            icon: <FontAwesomeIcon icon={faPrint} />,
          },
        ],
      },
      {
        label: 'Maintenance',
        icon: <FontAwesomeIcon icon={faTools} />,
        visible: true,
        items: [
          {
            ...externalLink,
            label: 'Create Work Order',
            href: `/process-automation?automationType=${AutomationType.CREATE_WORK_ORDER}`,
            icon: <FontAwesomeIcon icon={faFolderPlus} />,
            visible:
              Auth.isHavePermission('Create_Work_Orders__c') ||
              Auth.isHavePermission('Edit_Work_Orders__c'),
          },
          {
            ...internalLink,
            label: 'Work Orders',
            to: '/work-orders',
            icon: <FontAwesomeIcon icon={faListAlt} />,
            visible:
              Auth.isHavePermission('View_Work_Orders__c') ||
              Auth.isHavePermission('Create_Work_Orders__c') ||
              Auth.isHavePermission('Edit_Work_Orders__c'),
          },
          {
            ...internalLink,
            label: 'Work Order Dispatch',
            to: '/dispatch/0014100000akkGQAAY',
            icon: <FontAwesomeIcon icon={faRoute} />,
            visible: Auth.isHavePermission('Dispatch_Access__c:Work Orders'),
          },
          {
            ...internalLink,
            label: 'Vendor Manager',
            to: `/visual-manager/${ReportTypes.VENDORS}`,
            icon: <FontAwesomeIcon icon={faUserHardHat} />,
            visible: Auth.isHavePermission(
              'Visual_Manager__c:Property Manager Reports',
            ),
          },
          {
            ...internalLink,
            label: 'Price Book',
            to: '/price-book',
            icon: <FontAwesomeIcon icon={faTags} />,
            visible:
              Auth.isHavePermission('View_Pricebooks__c') ||
              Auth.isHavePermission('Edit_Pricebooks__c'),
          },
        ],
      },
      {
        label: 'Inspection',
        icon: <FontAwesomeIcon icon={faCameraPolaroid} />,
        visible: true,
        items: [
          {
            ...externalLink,
            label: 'Create Inspection',
            href: `/process-automation?automationType=${AutomationType.CREATE_INSPECTION}`,
            icon: <FontAwesomeIcon icon={faFolderPlus} />,
            visible: isAllowedProcessAutomation,
          },
          {
            ...internalLink,
            label: 'Inspections',
            to: '/inspections',
            icon: <FontAwesomeIcon icon={faListAlt} />,
          },
          {
            ...internalLink,
            label: 'Inspection Submissions',
            to: `/visual-manager/${ReportTypes.FASTFIELDS_INSPECTIONS}`,
            icon: <FontAwesomeIcon icon={faRoute} />,
            visible: Auth.isHavePermission('Inspection_Processing__c'),
          },
          {
            ...internalLink,
            label: 'Inspection Dispatch',
            to: '/dispatch/0014100000TVyrNAAT/inspections',
            icon: <FontAwesomeIcon icon={faRoute} />,
            visible: Auth.isHavePermission('Dispatch_Access__c:Inspections'),
          },
          {
            ...internalLink,
            label: 'Auto Scheduler',
            to: '/dispatch/0014100000TVyrNAAT/inspections/autoschedule',
            icon: <FontAwesomeIcon icon={faCogs} />,
            visible: Auth.isHavePermission('Dispatch_Auto_Scheduler__c'),
          },
        ],
      },
      {
        label: 'Sales',
        icon: <FontAwesomeIcon icon={faHandshake} />,
        visible: true,
        items: [
          {
            ...internalLink,
            label: 'Signup Coordination',
            to: `/visual-manager/${ReportTypes.SIMPLE_SIGNUP}`,
            icon: <FontAwesomeIcon icon={faFileCheck} />,
            visible: Auth.isHavePermission('Visual_Manager__c:Simple Signup'),
          },
          {
            ...internalLink,
            label: 'Simple Signup',
            to: '/simple-signup',
            icon: <FontAwesomeIcon icon={faFileSignature} />,
          },
          {
            ...internalLink,
            label: 'Rental Listings Leads',
            to: `/visual-manager/${ReportTypes.RENTAL_LISTING_LEADS}`,
            icon: <FontAwesomeIcon icon={faPeoplePulling} />,
            visible: Auth.isHavePermission(
              'Visual_Manager__c:Property Listing Reports',
            ),
          },
        ],
      },
      {
        label: 'Sign On',
        icon: <FontAwesomeIcon icon={faFilePdf} />,
        visible: true,
        items: [
          {
            ...externalLink,
            label: 'Envelopes',
            href: `${process.env.SIGN_ON_DOMAIN}/documents`,
            icon: <FontAwesomeIcon icon={faFilePdf} />,
            visible: Auth.isHavePermission('Sign_On__c'),
          },
          {
            ...externalLink,
            label: 'Templates',
            href: `${process.env.SIGN_ON_DOMAIN}/templates`,
            icon: <FontAwesomeIcon icon={faFile} />,
            visible: isAllowedTemplates,
          },
        ],
      },
      {
        label: 'Accounting',
        icon: <FontAwesomeIcon icon={faAbacus} />,
        visible: true,
        items: [
          {
            ...internalLink,
            label: 'On Q Accounts Payable',
            to: `/visual-manager/${ReportTypes.ACCOUNTS_PAYABLE_ONQ}`,
            icon: <FontAwesomeIcon icon={faMoneyCheckEditAlt} />,
            visible: Auth.isHavePermission('Accounts_Payable__c'),
          },
          {
            ...internalLink,
            label: 'Mammoth Accounts Payable',
            to: `/visual-manager/${ReportTypes.ACCOUNTS_PAYABLE_MAMMOTH}`,
            icon: <FontAwesomeIcon icon={faMoneyCheckEditAlt} />,
            visible: Auth.isHavePermission('Accounts_Payable__c'),
          },
          {
            ...internalLink,
            label: 'Company Card Recociliation',
            to: `/visual-manager/${ReportTypes.COMPANY_CARD_RECONCILIATION}`,
            icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
            visible: Auth.isHavePermission('Accounts_Payable__c'),
          },
          {
            ...internalLink,
            label: 'On Q Accounts Receivable',
            to: `/visual-manager/${ReportTypes.ACCOUNTS_RECEIVABLE_ONQ}`,
            icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
            visible: Auth.isHavePermission('Accounts_Receivable__c'),
          },
          {
            ...internalLink,
            label: 'Mammoth Accounts Receivable',
            to: `/visual-manager/${ReportTypes.ACCOUNTS_RECEIVABLE_MAMMOTH}`,
            icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
            visible: Auth.isHavePermission('Accounts_Receivable__c'),
          },
        ],
      },
      {
        ...externalLink,
        href: 'https://tenbar.atlassian.net/servicedesk',
        label: 'Help Desk',
        icon: <FontAwesomeIcon icon={faQuestionCircle} />,
        target: '_blank',
      },
      {
        label: 'Dashboards',
        icon: <FontAwesomeIcon icon={faChartLine} />,
        visible: true,
        items: [
          {
            ...internalLink,
            label: 'Phone User Status',
            to: '/reports/phone-user-status',
            icon: <FontAwesomeIcon icon={faPhoneOffice} />,
            target: '_blank',
          },
        ],
      },
      {
        label: 'Admin Tools',
        icon: <FontAwesomeIcon icon={faUserShield} />,
        visible: true,
        items: [
          {
            ...internalLink,
            label: 'Email Template Editor',
            to: '/email-templates',
            icon: <FontAwesomeIcon icon={faMailBulk} />,
            visible: Auth.isHavePermission('Dev_Tools__c'),
          },
          {
            ...internalLink,
            label: 'On Q Employees',
            to: '/account-manager/0014100000TVyrNAAT',
            icon: <FontAwesomeIcon icon={faUserHardHat} />,
            visible: Auth.isHavePermission('Dev_Tools__c'),
          },
          {
            ...internalLink,
            label: 'Mammoth Employees',
            to: '/account-manager/0014100000akkGQAAY',
            icon: <FontAwesomeIcon icon={faUserHardHat} />,
            visible: Auth.isHavePermission('Dev_Tools__c'),
          },
        ],
      },
    ];

    const pathname = `/${location.pathname.replace(/^\/|\/$/g, '')}`;

    // filter invisible
    return items.reduce((memo, item) => {
      if (!item.visible) {
        return memo;
      }

      // no items -> link
      if (!item.items) {
        memo.push({
          ...item,
          // detect selected
          selected:
            pathname === item.to ||
            pathname === item.href ||
            pathname.includes(item.to),
        });
      } else {
        // filter invisible
        const subItems = item.items.reduce((smemo, sub) => {
          if (!sub.visible) {
            return smemo;
          }
          smemo.push({
            ...sub,
            // detect selected
            selected:
              pathname === sub.to ||
              pathname === sub.href ||
              pathname.includes(sub.to),
          });
          return smemo;
        }, []);

        if (subItems.length) {
          memo.push({
            ...item,
            selected: subItems.some((sub) => sub.selected),
            items: subItems,
          });
        }
      }

      return memo;
    }, []);
  }, [location.pathname]);

  return menuItems;
};

export default useMenuItems;
