import React, { useState, useCallback, memo } from 'react';
import { bool, func, node, string } from 'prop-types';
import { Button } from '@mui/material';
import Input from 'components/CustomTextField';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';

// ------------------------------------
const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: theme.spacing(1),
  flexWrap: 'wrap',
}));

// ------------------------------------
const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    alignItems: 'flex-end',
    '& .MuiButton-root': {
      width: '100%',
    },
  },
}));

const ChildrenBox = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

// ------------------------------------
const StyledBoxSelect = styled('div')(({ theme }) => ({
  minWidth: theme.spacing(37.5),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

// ------------------------------------
const VmHeaderSearch = ({
  isLoading = false,
  children = null,
  defaultValue = '',
  buttonTitle = 'Search',
  placeholder = 'Search for',
  onSearch,
}) => {
  const [searchText, setSearchText] = useState(defaultValue);

  const onSearchChange = useCallback((e) => setSearchText(e.target.value), []);
  const onSearchSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSearch(searchText || '');
    },
    [searchText],
  );

  return (
    <MainContainer>
      <StyledForm onSubmit={onSearchSubmit} className="search-form">
        <StyledBoxSelect>
          <Input
            name="searchText"
            label={placeholder}
            margin="none"
            type="search"
            value={searchText}
            disabled={isLoading}
            onChange={onSearchChange}
          />
        </StyledBoxSelect>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
          startIcon={<SearchIcon />}
        >
          {buttonTitle}
        </Button>
      </StyledForm>
      {!!children && <ChildrenBox>{children}</ChildrenBox>}
    </MainContainer>
  );
};

VmHeaderSearch.propTypes = {
  isLoading: bool,
  children: node,
  defaultValue: string,
  buttonTitle: string,
  placeholder: string,
  onSearch: func.isRequired,
};

export default memo(VmHeaderSearch);
