/* eslint-disable indent */
import moment from 'moment';
import Auth from 'helpers/auth';
import { formatDate, formatEin, formatNonUtcDate } from 'helpers/format';
import { fixOutdatedMostRecentContract } from 'helpers/property';
import { decodeHtmlEntities } from 'helpers/general';
import { convertPropertyServices } from './property';
import {
  convertAccountContactRelations,
  convertContact,
  convertRecordType,
} from './general';
import { convertUser } from './user';
import { convertGroup } from './job';

// ------------------------------------
export const convertAppointment = (app) => {
  if (!app) {
    return null;
  }
  return {
    id: app.Id,
    status: app.Status__c || '',
    dateScheduled: app.Scheduled_Date_Time__c || '', // ISO Date
    dateCompleted: app.Completed_Date_Time__c || '', // ISO Date
    dateDeparted: app.Departed_Date_Time__c || '', // ISO Date
    scheduleWindow: app.Schedule_Window__c || 0,
    vendorTechnician: decodeHtmlEntities(app.Vendor_Technician_Name__c),
    isKeysReady: !!app.Keys_Ready__c,
    technician: convertUser(app.Technician__r),
    job: convertJob(app.Job__r),
    isTimeLocked: !!app.Is_Time_Locked__c,
    isSendReminder: !!app.Send_Reminder__c,
    isDayLocked: !!app.Is_Day_Locked__c,
  };
};

// ------------------------------------
export const convertAppointments = (apps = []) =>
  apps.map((app) => convertAppointment(app));

// ------------------------------------
export const convertExpense = (expense) => {
  if (!expense) {
    return null;
  }
  return {
    id: expense.Id,
    name: decodeHtmlEntities(expense.Name),
    dateCreated: expense.CreatedDate || '', // ISO Date
    dateLastModified: expense.LastModifiedDate || '', // ISO Date
    expenseType: expense.Type__c || '',
    dateDue: formatNonUtcDate(expense.Due_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    paymentsMade: expense.Payments_Made__c || 0,
    grandTotal: expense.Grand_Total__c || 0,
    numberOfPayments: expense.Number_of_Payments__c || 0,
    balanceDue: expense.Balance_Due__c || 0,
    isApprovalToPay: !!expense.Approval_To_Pay__c,
    vendorInvoiceReferenceNumber:
      expense.Vendor_Invoice_Reference_Number__c || '',
    notes: decodeHtmlEntities(expense.Notes__c),
    isOverridePaymentTerms: !!expense.Override_Payment_Terms__c,
    recordType: convertRecordType(expense.RecordType),
    lineItem: convertLineItem(expense.Line_Item__r),
    job: convertJob(expense.Job__r),
    vendor: convertAccount(expense.Vendor__r),
    workOrder: convertWorkOrder(expense.Work_Order__r),
    createdBy: convertUser(expense.CreatedBy),
    companyCard: convertCompanyCard(expense.Company_Card__r),
  };
};

// ------------------------------------
export const convertExpenses = (expenses = []) =>
  expenses.map((expense) => convertExpense(expense));

// ------------------------------------
export const convertInvoicePayment = (payment) => {
  if (!payment) {
    return null;
  }
  return {
    id: payment.Id,
    name: decodeHtmlEntities(payment.Name),
    memo: payment.Memo__c || '',
    amount: payment.Amount__c || 0,
    paymentType: payment.Payment_Type__c || '',
    referenceNumber: payment.Reference_Number__c || '',
  };
};

// ------------------------------------
export const convertInvoicePayments = (payments = []) =>
  payments.map((payment) => convertInvoicePayment(payment));

// ------------------------------------
export const convertInvoiceLineItem = (lineItem) => {
  if (!lineItem) {
    return null;
  }
  return {
    id: lineItem.Id,
    name: decodeHtmlEntities(lineItem.Name),
    privateNotes: decodeHtmlEntities(lineItem.Private_Notes__c),
    publicNotes: decodeHtmlEntities(lineItem.Public_Notes__c),
    subject: decodeHtmlEntities(lineItem.Subject__c),
    total: lineItem.Total__c || 0,
  };
};

// ------------------------------------
export const convertInvoiceLineItems = (lineItems = []) =>
  lineItems.map((lineItem) => convertInvoiceLineItem(lineItem));

// ------------------------------------
export const convertInvoice = (income) => {
  if (!income) {
    return null;
  }

  let billToContract = null;
  if (income.Bill_To_Contract__r) {
    billToContract =
      income.Bill_To_Contract__r?.RecordType?.Name === 'Owner Agreement'
        ? convertOwnerContract(income.Bill_To_Contract__r)
        : convertTenantContract(income.Bill_To_Contract__r);
  }

  let invoiceFor = '';
  if (income.Work_Order__r) {
    invoiceFor = 'Work Order';
  }
  if (income.Property_Listing__r) {
    invoiceFor = 'Property Listing';
  }
  if (income.Property_Inspection__r) {
    invoiceFor = 'Inspection';
  }
  if (income.Opportunity__r) {
    invoiceFor = 'Opportunity';
  }

  return {
    id: income.Id,
    invoiceFor,
    name: decodeHtmlEntities(income.Name),
    dateCreated: income.CreatedDate || '', // ISO Date
    status: income.Status__c || 'Unpaid',
    isBillCreated: !!income.Bill_Created__c,
    grandTotal: income.Grand_Total__c || 0,
    totalPrice: income.Total_Price__c || 0,
    paymentsReceived: income.Payments_Received__c || 0,
    numberOfPayments: income.Number_of_Payments__c || 0,
    balanceDue: income.Balance_Due__c || 0,
    dateDue: formatNonUtcDate(income.Due_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    datePaid: formatNonUtcDate(income.Date_Paid__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    recordType: convertRecordType(income.RecordType),
    billingMethod: income.Billing_Method__c || '',
    billToDirect: convertAccount(income.Bill_To_Direct__r),
    billToContract,
    lineItems: convertInvoiceLineItems(income.Income_Line_Items__r?.records),
    payments: convertInvoicePayments(income.Invoice_Payment__r?.records),
    workOrder: convertWorkOrder(income.Work_Order__r),
    inspection: convertInspection(income.Property_Inspection__r),
    opportunity: convertOpportunity(income.Opportunity__r),
    propertyListing: convertListing(income.Property_Listing__r),
    market: convertMarket(income.Market__r),
    expenses: convertExpenses(income.Expenses__r?.records),
    isHidden: !!income.Hide__c,
  };
};

// ------------------------------------
export const convertInvoices = (incomes = []) =>
  incomes.map((income) => convertInvoice(income));

// ------------------------------------
export const convertJob = (job) => {
  if (!job) {
    return null;
  }
  return {
    id: job.Id,
    name: decodeHtmlEntities(job.Name),
    subject: decodeHtmlEntities(job.Subject__c),
    status: job.Status__c || '',
    dateScheduled: job.Scheduled_Date_Time__c || '', // ISO Date
    isVendorInitialNotice: !!job.Vendor_Initial_Notice__c,
    maintenanceGroup: convertGroup(job.Agent_Group__r),
    vendor: convertAccount(job.Vendor__r),
    nextAppointment: convertAppointment(job.Next_Open_Job_Appointment__r),
    appointments: convertAppointments(job.Job_Appointments__r?.records),
    lineItems: convertLineItems(job.Work_Order_Line_Items__r?.records),
    expenses: convertExpenses(job.Expenses__r?.records),
    workOrder: convertWorkOrder(job.Work_Order__r),
    inspection: convertInspection(job.Property_Inspection__r),
  };
};

// ------------------------------------
export const convertJobs = (jobs = []) => jobs.map((job) => convertJob(job));

// ------------------------------------
export const convertLineItem = (lineItem) => {
  if (!lineItem) {
    return null;
  }
  return {
    id: lineItem.Id,
    name: decodeHtmlEntities(lineItem.Name),
    order: lineItem.Display_Order__c || 0,
    subject: decodeHtmlEntities(lineItem.Subject__c || lineItem.Subject || ''),
    isGroup: !!lineItem.Is_Group__c,
    isHidden: !!lineItem.Hide__c,
    quantity: lineItem.Quantity || 0,
    unitPrice: lineItem.UnitPrice || 0,
    lineItemNumber: lineItem.LineItemNumber || '',
    discount: lineItem.Discount || 0,
    condition: lineItem.Condition__c || '',
    isStatusApproved: !!lineItem.Status_Approved__c,
    responsibleParty: lineItem.Responsible_Party__c || 'Owner',
    tenantResponsibilityPercent: lineItem.Tenants_Responsibility__c || 0,
    estimatedExpenses: lineItem.Estimated_Expenses__c || 0,
    tenantTotalPrice: lineItem.Tenant_TotalPrice__c || 0,
    group: convertLineItem(lineItem.ParentWorkOrderLineItem),
    space: lineItem.Space__c || '',
    area: lineItem.Area__c || '',
    isStandard: !!lineItem.On_Q_Standard__c,
    status: lineItem.Status || '',
    vendor: convertAccount(lineItem.Vendor__r),
    totalPrice: lineItem.TotalPrice || 0,
    job: convertJob(lineItem.Job__r),
    // invoice: convertInvoice(lineItem.Invoice__r),
    expenses: convertExpenses(lineItem.Expenses__r?.records),
    // skip iso dates conversion here
    dateCreated: lineItem.Date_Created__c || '', // ISO Date
    datePendingApproval: lineItem.Date_Pending_Approval__c || '', // ISO Date
    dateApproved: lineItem.Date_Approved__c || '', // ISO Date
    dateWorkCompleted: lineItem.Date_Work_Completed__c || '', // ISO Date
    dateWorkVerification: lineItem.Date_Work_Verification__c || '', // ISO Date
    dateCompleted: lineItem.Date_Completed__c || '', // ISO Date
    dateCannotComplete: lineItem.Date_Cannot_Complete__c || '', // ISO Date
    dateDeclined: lineItem.Date_Declined__c || '', // ISO Date
  };
};

// ------------------------------------
export const convertLineItems = (lineItems = []) =>
  lineItems.map((lineItem) => convertLineItem(lineItem));

// ------------------------------------
export const convertWorkOrder = (workOrder) => {
  if (!workOrder) {
    return null;
  }

  return {
    id: workOrder.Id,
    number: workOrder.WorkOrderNumber || '',
    status: workOrder.Status || '',
    workOrderType: workOrder.Type__c || '',
    subject: decodeHtmlEntities(workOrder.Subject),
    tax: workOrder.Tax || 0,
    approvedTax: workOrder.Approved_Tax__c || 0,
    description: decodeHtmlEntities(workOrder.Description),
    internalNotes: decodeHtmlEntities(workOrder.Internal_Notes__c),
    preApprovalAmount: workOrder.Pre_Approved_Amount__c || 0,
    nextOpenTask: workOrder.Next_Open_Task__c || '',
    dateNextOpenTask: workOrder.Date_Next_Open_Task__c || '',
    isEmergency: !!workOrder.Emergency_Work_Order__c,
    dateLastCompanyChange: formatNonUtcDate(
      workOrder.Date_Last_Company_Change__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateWorkCompletedExpected: formatNonUtcDate(
      workOrder.Date_Work_Completed_Expected__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateCreated: workOrder.CreatedDate || '', // ISO Date
    dateInvoiced: workOrder.Date_Invoiced__c || '', // ISO Date
    datePendingApproval: workOrder.Date_Pending_Approval__c || '', // ISO Date
    grandTotal: workOrder.GrandTotal || 0,
    approvedTotalPrice: workOrder.Approved_Total_Price__c || 0,
    approvedTenantTotalPrice: workOrder.Approved_Tenant_Total__c || 0,
    approvedOwnerTotalPrice: workOrder.Approved_Owner_Total__c || 0,
    totalExpenses: workOrder.Total_Expenses__c || 0,
    numberOfExpensePayments: workOrder.Number_of_Expense_Payments__c || 0,
    expenseBalanceDue: workOrder.Expense_Balance_Due__c || 0,
    estimatedExpenses: workOrder.Estimated_Expenses__c || 0,
    workOrderBalance: workOrder.WorkOrder_Balance__c || 0,
    totalInvoiced: workOrder.Total_Invoiced__c || 0,
    numberOfInvoicePayments: workOrder.Number_of_Invoice_Payments__c || 0,
    invoiceBalanceDue: workOrder.Invoice_Balance_Due__c || 0,
    workOrderCollected: workOrder.WorkOrder_Collected__c || 0,
    inspection: convertInspection(workOrder.Property_Inspection__r),
    account: convertAccount(workOrder.Account),
    recordType: convertRecordType(workOrder.RecordType),
    property: convertProperty(workOrder.Property__r),
    lineItems: convertLineItems(workOrder.WorkOrderLineItems?.records),
    jobs: convertJobs(workOrder.Jobs__r?.records),
    schedulingContact: convertUser(workOrder.Scheduling_Contact__r),
    maintenanceCoordinator: convertUser(workOrder.Coordinator__r),
    daysOpen: workOrder.Days_Open__c || 0,
    daysToPendingApproval: workOrder.Days_to_Pending_Approval__c || 0,
    daysToWorkCompleted: workOrder.Days_to_Work_Completed__c || 0,
    daysToInvoiced: workOrder.Days_to_Invoiced__c || 0,
    numberOfApprovedLineItems: workOrder.Number_of_Approved_Line_Items__c || 0,
    isWorkOrderMatchesUserCompany: Auth.isWorkOrderMatchesUserCompany(
      workOrder.RecordType?.Id || '',
    ),
    dateOwnerNotified: formatNonUtcDate(
      workOrder.Date_Owner_Notified__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
  };
};

// ------------------------------------
export const convertWorkOrders = (workOrders = []) =>
  workOrders.map((wo) => convertWorkOrder(wo));

// ------------------------------------
export const convertCompanyCard = (card) => {
  if (!card) {
    return null;
  }
  return {
    id: card.Id,
    number: card.Number__c || '',
    nameOnCard: decodeHtmlEntities(card.Name_on_Card__c),
    cvv: card.CVV__c || '',
    expirationDay: card.Expiration_Day__c || '',
    expirationMonth: card.Expiration_Month__c || '',
    expirationYear: card.Expiration_Year__c || '',
    billingStreetAddress: card.Billing_Street_Address__c || '',
    billingUnit: card.Billing_Unit__c || '',
    billingCity: card.Billing_City__c || '',
    billingState: card.Billing_State__c || '',
    billingZip: card.Billing_Zip__c || '',
    assignedTo: convertUser(card.Assigned_To__r),
    company: convertAccount(card.Company__r),
  };
};

// ------------------------------------
const convertContractPet = (pet) => {
  if (!pet) {
    return null;
  }
  return {
    id: pet.Id,
    status: pet.Status__c || '',
    type: pet.Type__c || '',
    breed: pet.Breed__c || '',
    count: pet.Count__c || 0,
    isServiceAnimal: !!pet.Service_Animal__c,
  };
};

// ------------------------------------
export const convertContractPets = (pets = []) =>
  pets.map((pet) => convertContractPet(pet));

// ------------------------------------
const convertDocusignRecipient = (recipient) => {
  if (!recipient) {
    return null;
  }

  return {
    id: recipient.Id,
    name: decodeHtmlEntities(recipient.Name),
    parentStatusRecord: recipient.dsfs__Parent_Status_Record__c || '',
    recipientEmail: recipient.dsfs__DocuSign_Recipient_Email__c || '',
    recipientStatus: recipient.dsfs__Recipient_Status__c || '',
    recipientId: recipient.dsfs__DocuSign_Recipient_Id__c || '',
    dateSent: recipient.dsfs__Date_Sent__c || '', // ISO
    dateDelivered: recipient.dsfs__Date_Delivered__c || '', // ISO
    dateSigned: recipient.dsfs__Date_Signed__c || '', // ISO
    dateDeclined: recipient.dsfs__Date_Declined__c || '', // ISO
    declineReason: recipient.dsfs__Decline_Reason__c || '',
    envelopeId: recipient.dsfs__DocuSign_Envelope_ID__c || '',
    envelopeLink: recipient.dsfs__Envelope_Link__c || '',
    envelopeStatus: recipient.dsfs__Envelope_Status__c || '',
  };
};

// ------------------------------------
const convertDocusignRecipients = (recipients = []) =>
  recipients.map((recipient) => convertDocusignRecipient(recipient));

// ------------------------------------
const convertDocusignStatus = (docusign) => {
  if (!docusign) {
    return null;
  }
  return {
    id: docusign.Id,
    name: decodeHtmlEntities(docusign.Name),
    envelopeStatus: docusign.dsfs__Envelope_Status__c || '',
    envelopeLink: docusign.dsfs__Envelope_Link__c || '',
    sentDate: docusign.dsfs__Sent_Date_Time__c || '', // ISO
    sentAge: docusign.dsfs__Sent_Age__c || 0,
    envelopeId: docusign.dsfs__DocuSign_Envelope_ID__c || '',
    senderEmail: docusign.dsfs__Sender_Email__c || '',
    recipients: convertDocusignRecipients(
      docusign.dsfs__R00NS0000000WUO2MAO__r?.records,
    ),
  };
};

// ------------------------------------
export const convertContractAddendum = (addendum) => {
  if (!addendum) {
    return null;
  }
  return {
    id: addendum.Id,
    name: `${decodeHtmlEntities(addendum.Name)} ${decodeHtmlEntities(
      addendum.RecordType?.Name,
    )}`,
    addendumName: decodeHtmlEntities(addendum.Name),

    recordType: convertRecordType(addendum.RecordType),
    dateCreated: formatDate(addendum.CreatedDate, 'MM/DD/YYYY') || '', // ISO Date
    status: addendum.Status__c || '',
    dateAddendumStart:
      formatNonUtcDate(addendum.Addendum_Start_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    dateContractEnd:
      formatNonUtcDate(addendum.Contract_End_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    publicAccessCode: addendum.Public_Access_Code__c || '',
    ownerComments: addendum.Comments_Owner__c || '',
    tenantComments: addendum.Comments_Tenant__c || '',
    daysInCurrentStatus: addendum.Days_in_Current_Status__c || '',
    docusignStatus: convertDocusignStatus(addendum.DocuSign_Status__r),
    contract: convertTenantContract(addendum.Contract__r),
    addendumTerms: addendum.Addendum_Terms__c || '',

    rentRateMonthly: addendum.Rent_Rate_monthly__c || 0,
    otherRent: addendum.Other_Rent__c || 0,
    petRent: addendum.Pet_Rent__c || 0,
    parkingRent: addendum.Parking_Rent__c || 0,
    storageRent: addendum.Storage_Rent__c || 0,
    rubs: addendum.RUBS__c || 0,
    adminTaxPercentage: addendum.Admin_Tax_Percentage__c || 0,
    residentBenefitsPackage: addendum.Resident_Benefits_Package__c || '',
    residentBenefitsPackagePrice:
      addendum.Resident_Benefits_Package_Price__c || 0,
    securityDepositType: addendum.Security_Deposit_Type__c || '', // Formula takes it from the Contract
    securityDepositAlternativeRateMonth:
      addendum.Security_Deposit_Alternative_Rate_Month__c || 0, // Formula takes it from the Contract

    isLeaseRenewalAttachmentPets: !!addendum.Lease_Renewal_Attachment_Pets__c,
    isLeaseRenewalAttachmentPool: !!addendum.Lease_Renewal_Attachment_Pool__c,
    isLeaseRenewalAttachmentPaint: !!addendum.Lease_Renewal_Attachment_Paint__c,
    isLeaseRenewalAttachmentHandbook:
      !!addendum.Lease_Renewal_Attachment_Handbook__c,
    dateSnoozeTill:
      formatNonUtcDate(addendum.Snooze_Till_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    datePaymentStart:
      formatNonUtcDate(addendum.Payment_Start_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    datePaymentEnd:
      formatNonUtcDate(addendum.Payment_End_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    totalBalance: addendum.Total_Balance__c || 0,
    fee: addendum.Fee__c || 0,
    planBreakdown: addendum.Plan_Breakdown__c || '',
    comment: addendum.Comments__c || '',
    evictionOutcome: addendum.Eviction_Outcome__c || '',
    dateCourt: formatNonUtcDate(addendum.Court_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    isOfferMonthToMonth: !!addendum.Offer_Month_to_Month__c,
    rentRate: addendum.Rent_Rate__c || 0,
    rentRateIncrease: addendum.Rent_Rate_Increase__c || 0,

    signOnDocument: addendum.signOnDocument,
  };
};

// ------------------------------------
export const convertContractAddendums = (contractAddendums = []) =>
  contractAddendums.map((contractAddendum) =>
    convertContractAddendum(contractAddendum),
  );

// ------------------------------------
const convertCollection = (collection) => {
  if (!collection) {
    return null;
  }
  return {
    id: collection.Id,
    status: collection.Status__c || '',
  };
};

// ------------------------------------
export const convertCollections = (collections = []) =>
  collections.map((collection) => convertCollection(collection));

// ------------------------------------
const convertContractViolation = (violation) => {
  if (!violation) {
    return null;
  }
  return {
    id: violation.Id,
    name: decodeHtmlEntities(violation.Name),
    dateOccurred:
      formatNonUtcDate(violation.Date_Occurred__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    type: violation.Type__c,
    processingFee: violation.Processing_Fee__c || 0,
    violationFine: violation.Violation_Fine__c || 0,
    description: decodeHtmlEntities(violation.Description__c),
  };
};

// ------------------------------------
export const convertContractViolations = (contractViolations = []) =>
  contractViolations.map((contractViolation) =>
    convertContractViolation(contractViolation),
  );

// ------------------------------------
export const convertContract = (contract) => {
  if (!contract) {
    return null;
  }

  let daysSinceStartDate = 0;
  if (contract.StartDate) {
    const now = moment();
    daysSinceStartDate = now.diff(
      moment(contract.StartDate, 'YYYY-MM-DD'),
      'days',
    );
  }

  return {
    id: contract.Id || '',
    name: decodeHtmlEntities(contract.Name),
    accountId: contract.AccountId || '',
    pets: convertContractPets(contract.Contract_Pets__r?.records),
    contractAddendums: convertContractAddendums(contract.Addendums__r?.records),
    contractViolations: convertContractViolations(
      contract.Contract_Violations__r?.records || [],
    ),
    // --- Common:
    contractNumber: contract.ContractNumber || '',
    status: contract.Status || '',
    dateStart: formatNonUtcDate(contract.StartDate, 'MM/DD/YYYY'), // YYYY-MM-DD
    daysSinceStartDate,
    dateEnd: formatNonUtcDate(contract.EndDate, 'MM/DD/YYYY'), // YYYY-MM-DD
    market: convertMarket(contract.Market__r),
    specialTerms: contract.SpecialTerms || '',
    combinedContactNames: decodeHtmlEntities(
      contract.Combined_Contact_Names__c,
    ),

    contact1: convertContact(contract.Contact_1__r),
    contact2: convertContact(contract.Contact_2__r),
    contact3: convertContact(contract.Contact_3__r),
    account: convertAccount(contract.Account),
    property: convertProperty(contract.Property__r),

    // Key check in:
    keysReturnedHouse: contract.Keys_Returned_House__c || 0,
    keysReturnedMailbox: contract.Keys_Returned_Mailbox__c || 0,
    remotesReturnedGarage: contract.Remotes_Returned_Garage__c || 0,
    remotesReturnedGate: contract.Remotes_Returned_Gate__c || 0,
    keysReturnedGate: contract.Keys_Returned_Gate__c || 0,
    keysReturnedLaundry: contract.Keys_Returned_Laundry__c || 0,
    keysReturnedPool: contract.Keys_Returned_Pool__c || 0,
    keysReturnedNotes: decodeHtmlEntities(contract.Keys_Returned_Notes__c),

    // Key check out:
    keysGivenHouse: contract.Keys_Given_House__c || 0,
    keysGivenMailbox: contract.Keys_Given_Mailbox__c || 0,
    remotesGivenGarage: contract.Remotes_Given_Garage__c || 0,
    remotesGivenGate: contract.Remotes_Given_Gate__c || 0,
    keysGivenGate: contract.Keys_Given_Gate__c || 0,
    keysGivenLaundry: contract.Keys_Given_Laundry__c || 0,
    keysGivenPool: contract.Keys_Given_Pool__c || 0,
    keysGivenNotes: decodeHtmlEntities(contract.Keys_Given_Notes__c),

    terminatedReason: contract.Terminated_Reason__c || '',
    terminationExplanation: contract.Termination_Explanation__c || '',
    terminationNotes: decodeHtmlEntities(contract.Termination_Notes__c),

    // Maintenance Preferences:
    maintainHoaDuesOwner: contract.Maintain_HOA_Dues_Owner__c || '',
    maintainLandscapingOwner: contract.Maintain_Landscaping_Owner__c || '',
    maintainWhichLandscapingOwner:
      contract.Maintain_Which_Landscaping_Owner__c || '',
    maintainLandscapingFrequency:
      contract.Maintain_Landscaping_Frequency__c || '',
    maintainPoolOwner: contract.Maintain_Pool_Owner__c || '',
    maintainPoolFrequency: contract.Maintain_Pool_Frequency__c || '',
    maintainPestControlOwner: contract.Maintain_Pest_Control_Owner__c || '',
    maintainPestFrequency: contract.Maintain_Pest_Frequency__c || '',

    dateTerminated: formatNonUtcDate(contract.Date_Terminated__c, 'MM/DD/YYYY'),
    terminationReason: contract.Terminated_Reason__c || '',
    licenseNumber: contract.TPT_License_Number__c || '',
    propertyManager: convertPropertyManager(contract.Property_Manager__r),
    signOnDocument: contract.signOnDocument,
    recordType: convertRecordType(contract.RecordType),
    parkingRent: contract.Parking_Rent__c || 0,
    rubs: contract.RUBS__c || 0,
    otherRent: contract.Other_Rent__c || 0,
    storageRent: contract.Storage_Rent__c || 0,
    petFeeOwner: contract.Pet_Fee__c || 0,
    contractTerm: contract.ContractTerm || '',
    petRent: contract.Pet_Rent__c || 0,
    petDeposit: contract.Pet_Deposit__c || 0,
    petFeeOnQ: contract.Pet_Fee_On_Q__c || 0,
    dateStartHoldingDeposit: formatNonUtcDate(
      contract.Holding_Deposit_Start_Date__c,
      'MM/DD/YYYY',
    ),
  };
};

export const convertMarket = (market) => {
  if (!market) {
    return null;
  }

  const address = market.ExtraInfo?.address;

  const convertedMarket = {
    id: market.Id || '',
    name: decodeHtmlEntities(market.Name),
    phone: market.Phone__c || market.ExtraInfo?.phone || '',
    fullAddress: market.Full_Address__c || address?.fullAddress || '',
    streetAddress: address?.street || '',
    streetAddress2: address?.street2 || '',
    city: address?.city || '',
    state: address?.state || '',
    zip: address?.zip || '',
    lat: address?.lat || '',
    lng: address?.lng || '',
    email: market.ExtraInfo?.email || '',
    website: market.ExtraInfo?.website || '',
    googleLink: address?.googleLink || '',
    abbreviation: market.Abbreviation__c || '',
  };

  if (market.ExtraInfo) {
    const {
      defaultAdminTaxPercentage,
      defaultSecurityDepositAlternativePercentage,
      defaultSecurityDepositRefundable,
      defaultLeaseSigningFee,
      defaultPetRent,
      defaultPetFeeOnQ,
      isDepositAlternativeAvailableLease,
    } = market.ExtraInfo;

    convertedMarket.defaultValues = {
      adminTaxPercentage: defaultAdminTaxPercentage || 0,
      securityDepositAlternativePercentage:
        defaultSecurityDepositAlternativePercentage || 0,
      securityDepositRefundable: defaultSecurityDepositRefundable || '',
      leaseSigningFee: defaultLeaseSigningFee || 0,
      petRent: defaultPetRent || 0,
      petFeeOnQ: defaultPetFeeOnQ || 0,
      isDepositAlternativeAvailableLease: !!isDepositAlternativeAvailableLease,
    };
  }

  return convertedMarket;
};

export const convertOpportunityMarkets = (markets) =>
  markets.reduce((memo, r) => {
    memo[r.Name] = r.Id;
    return memo;
  }, {});

// ------------------------------------
export const convertOwnerContract = (contract) => {
  if (!contract) {
    return null;
  }
  return {
    ...convertContract(contract),
    opportunity: convertOpportunity(contract.Opportunity__r),
    workOrderProtocol: contract.Work_Order_Protocol__c || '',
    isWorkOrderExcludeMammoth: !!contract.Work_Order_Exclude_Mammoth__c,

    // Management fees:
    maintenanceLimit: contract.Maintenance_Limit__c || '',
    reserveMinimum: contract.Reserve_Minimum__c || '',
    monthsFree: contract.Months_Free__c || '',
    dateToApplyManagementFee: formatNonUtcDate(
      contract.Date_to_Apply_Management_Fee__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    managementFee: contract.Management_Fee__c || '',
    leaseRenewalFee: contract.Lease_Renewal_Fee__c || '',
    marketingFee: contract.Marketing_Fee__c || '',
    leaseFee: contract.Lease_Fee__c || '',
    marketMlsCommissionsOwner: contract.Market_MLS_Commissions_Owner__c || 0,
    isMarketMlsOwner: !!contract.Market_MLS_Owner__c,

    // Eviction Protection:
    evictionProtectionStatus: contract.Eviction_Protection__c || '',
    evictionProtectionFee: contract.Eviction_Protection_Fee__c || '',
    evictionProtectionStartDate: formatNonUtcDate(
      contract.Eviction_Protection_Start_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD,

    // Routine Inspection:
    routineInspectionStatus: contract.Routine_Inspection__c || '',
    routineInspectionFee: contract.Routine_Inspection_Fee__c || '',
    routineInspectionMonthlyInterval:
      contract.Routine_Inspection_Monthly_Interval__c || 0,
    routineInspectionStartDate: formatNonUtcDate(
      contract.Routine_Inspection_Start_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD,
    routineInspectionFirstRun: formatNonUtcDate(
      contract.Routine_Inspection_First_Run__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD,
    dateRoutineInspectionLastRequest: formatNonUtcDate(
      contract.Date_Routine_Inspection_Last_Request__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD,
    dateRoutineInspectionNextRequest: formatNonUtcDate(
      contract.Date_Routine_Inspection_Next_Request__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD,

    // Filter Service:
    filterServiceStatus: contract.Filter_Service__c || '',
    filterServiceFee: contract.Filter_Service_Fee__c || '',
    filterServiceMonthlyInterval:
      contract.Filter_Service_Monthly_Interval__c || '',
    filterServiceStartDate: formatNonUtcDate(
      contract.Filter_Service_Start_Date__c,
      'MM/DD/YYYY',
    ),
    filterServiceFirstRun: formatNonUtcDate(
      contract.Filter_Service_First_Run__c,
      'MM/DD/YYYY',
    ),
    dateFilterServiceLastRequest: formatNonUtcDate(
      contract.Date_Filter_Service_Last_Request__c,
      'MM/DD/YYYY',
    ),
    dateFilterServiceNextRequest: formatNonUtcDate(
      contract.Date_Filter_Service_Next_Request__c,
      'MM/DD/YYYY',
    ),

    // Lease Preferences & Terms:
    targetRentRateOwner: contract.Target_Rent_Rate_Owner__c || '',
    isDepositAlternativeAvailable: !!contract.Deposit_Alternative_Available__c,
    hasLeaseAgreementAlternative: contract.hasLeaseAgreementAlternative,
    isResidentBenefitsPackageAvailable:
      !!contract.Resident_Benefits_Package_Available__c,
    additionalLeaseTerms: contract.Additional_Lease_Terms_Owner__c || '',
    preferredLeaseTerms: contract.Lease_Terms__c || '',
    otherIncluded: contract.Other_Included__c || '',
    appliancesWarranty: contract.Appliances_Warranty_Owner__c || '',
    petPolicy: contract.Combined_Pet_Policy__c || '',
    isAllowPetsOwner: !!contract.Allow_Pets_Owner__c,
    dogsAllowed: !!contract.Allow_Dogs_Owner__c,
    catsAllowed: !!contract.Allow_Cats_Owner__c,
    petRestrictions: contract.Pet_Restrictions__c || '',

    isOcOwnerInPw: !!contract.oc_Owner_In_PW__c,
    isOcKeysReceived: !!contract.oc_Keys_Received__c,

    isRecurringServicesStopped: !!contract.term_Recurring_Services_Stopped__c,
    isUtilitiesStopped: !!contract.term_Utilities_Stopped__c,
    isKeysCheckedOut: !!contract.term_Keys_Checked_Out__c,
    isTenantNotified: !!contract.term_Tenant_Notified__c,
    countyOwnerRegistration: contract.County_Rental_Registration__c || '',

    tptLicenseNumber: contract.TPT_License_Number__c || '',
    dateTptLicenseStart: formatNonUtcDate(
      contract.TPT_License_Start_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateTptLicenseIssued: formatNonUtcDate(
      contract.TPT_License_Issued_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateTptLicenseExpires: formatNonUtcDate(
      contract.TPT_License_Expires_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateTptSubmission: formatNonUtcDate(
      contract.TPT_Submission_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    tptLicenseLocationCode: contract.TPT_License_Location_Code__c || '',
    tptLicenseBusinessCode: contract.TPT_License_Business_Code__c || '',
    tptLicenseRegion: contract.TPT_License_Region__c || '',
    tptLicenseJurisdiction: contract.TPT_License_Jurisdiction__c || '',

    isRbpAvailableLease: !!contract.RBP_Available_Lease__c,

    additionalOccupants: contract.Additional_Occupants__c || '',
  };
  // eslint-disable-next-line no-restricted-syntax
};

export const convertPropertyManager = (propertyManager) => {
  if (!propertyManager) {
    return null;
  }

  return {
    id: propertyManager.Id || '',
    name: decodeHtmlEntities(propertyManager.Name),
  };
};

// ------------------------------------
export const convertTenantContract = (contract) => {
  if (!contract) {
    return null;
  }

  return {
    ...convertContract(contract),
    daysTillDisposition: contract.Days_Till_Disposition__c || 0,
    dateDateDispositionCompleted: formatNonUtcDate(
      contract.Date_Disposition_Completed__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateMoveOut: formatNonUtcDate(contract.Move_Out_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateMoveIn: formatNonUtcDate(contract.Move_In_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    securityDeposit: contract.Security_Deposit__c || 0,
    dateEarlyTermination: formatNonUtcDate(
      contract.Early_Termination_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    // --- Tenant:

    addendumTerms: contract.Addendum_Terms__c || '',
    // Details:
    securityDepositType: contract.Security_Deposit_Type__c || '',
    securityDepositAlternativePercentage:
      contract.Security_Deposit_Alternative_Percentage__c || 0,
    holdingDepositCalc: contract.Holding_Deposit_Calc__c || '',
    holdingDepositPaymentMethod:
      contract.Holding_Deposit_Payment_Method__c || '',
    deposit: contract.Deposit__c || 0,
    adminDeposit: contract.Admin_Deposit__c || 0,
    securityDepositRefundable: contract.Security_Deposit_Refundable__c || '',
    rentRateLease: contract.Rent_Rate_Lease__c || 0,
    otherRentDescription: decodeHtmlEntities(
      contract.Other_Rent_Description__c,
    ),
    totalRentRate: contract.Total_Rent_Rate__c || 0,
    adminTaxFee: contract.Admin_Tax_Fee__c || 0,
    securityDepositAlternativeRateMonth:
      contract.Security_Deposit_Alternative_Rate_Month__c || 0,
    rentRateMonthlyWAdminFee: contract.Rent_Rate_monthly_w_Admin_Fee__c || 0,
    dateCreated: contract.CreatedDate || '', // ISO Date
    dateKeysReturned: contract.Date_Keys_Returned__c || '', // ISO Date
    dateKeysGiven: contract.Date_Keys_Given__c || '', // ISO Date
    isOcLeaseInPw: !!contract.oc_Lease_In_PW__c,
    isOcSdCollected: !!contract.oc_SD_Collected__c,
    isOcChangeLetterSent: !!contract.oc_Change_Letter_Sent__c,
    isKeysCheckedIn: !!contract.term_Keys_Checked_In__c,
    isRemoveFees: !!contract.term_Remove_Fees__c,
    dateDispositionCompleted: formatNonUtcDate(
      contract.Date_Disposition_Completed__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    isRemoveAutoCharges: !!contract.term_Remove_Auto_Charges__c,
    monthToMonthReason: contract.Month_to_Month_Reason__c || '',
    additionalOccupants: contract.Additional_Occupants__c || '',
    combinedPets: contract.Combined_Pets__c || '',
    combinedHoa: contract.Combined_HOA__c || '',
    combinedUtilities: contract.Combined_Utilities__c || '',
    combinedAppliance: contract.Combined_Appliance__c || '',
    noticeToVacate: contract.Notice_to_Vacate__c || '',
    rentRateMonthly: contract.Rent_Rate_monthly__c || 0,
    adminTaxPercentage: contract.Admin_Tax_Percentage__c || 0,
    residentBenefitsPackage: contract.Resident_Benefits_Package__c || '',
    residentBenefitsPackagePrice:
      contract.Resident_Benefits_Package_Price__c || 0,
    numberOfPets: contract.Number_of_Pets__c || 0,
    leasingAgent: convertUser(contract.Leasing_Agent__r),
    isMoveInFundsCollected: !!contract.Move_In_Funds_Collected__c,
    isLeasingUtilitiesConfirmed: !!contract.Leasing_Utilities_Confirmed__c,
    monthToMonthIncreaseType: contract.Month_to_Month_Increase_Type__c || '',
    monthToMonthIncreaseAmount: contract.Month_to_Month_Increase_Amount__c || 0,
    totalDueUponMoveIn: contract.Total_Due_Upon_Move_In__c || 0,
    isSsnDobUpdated: !!contract.SSN_DOB_Updated_c__c,
    isLeasingInspectionScheduled: !!contract.Leasing_Inspection_Scheduled__c,
    leaseSigningFee: contract.Lease_Signing_Fee__c || 0,
    otherDeposit: contract.Other_Deposit__c || 0,
    otherDepositDescription: contract.Other_Deposit_Description__c || '',
    isOtherDepositRefundable: !!contract.Other_Deposit_Refundable__c,
    isOfferSecurityDepositAlternative:
      !!contract.Security_Deposit_Alternative_Offer__c,
    rentRatePerDayWithAdminFee: contract.Rent_Rate_per_day_w_Admin_Fee__c || 0,
    securityDepositAlternativeEnrollment:
      contract.Security_Deposit_Alternative_Enrollment__c || 0,
    securityDepositAlternativeCalc:
      contract.Security_Deposit_Alternative_Calc__c || 0,
    totalRentProratedWithAdminFeeAltCalc:
      contract.Total_Rent_Prorated_w_Admin_Fee_Alt_Calc__c || 0,
    rentFirstMonthWithAdminFeeAltCalc:
      contract.Rent_First_Month_w_Admin_Fee_Alt_Calc__c || 0,
    concessions: contract.Concessions__c || 0,
    totalDueUponMoveInAltCalc: contract.Total_Due_Upon_Move_In_Alt_Calc__c || 0,
    sdaDueNowBreakdown: contract.SDA_Due_Now_Breakdown__c || '',
    sdaDueAtMoveInBreakdown: contract.SDA_Due_At_Move_In_Breakdown__c || '',
    rentRateBreakdown: contract.Rent_Breakdown__c || '',
    totalRentProratedWithAdminFeeCalc:
      contract.Total_Rent_Prorated_w_Admin_Fee_Calc__c || 0,
    rentFirstMonthWithAdminFeeCalc:
      contract.Rent_First_Month_w_Admin_Fee_Calc__c || 0,
    totalDueUponMoveInCalc: contract.Total_Due_Upon_Move_In_Calc__c || 0,
    tsdDueNowBreakdown: contract.TSD_Due_Now_Breakdown__c || '',
    tsdDueAtMoveInBreakdown: contract.TSD_Due_At_Move_In_Breakdown__c || '',
    isDepositAlternativeAvailableLease:
      !!contract.Deposit_Alternative_Available_Lease__c,
  };
};

// ------------------------------------
export const convertInspection = (inspection) => {
  if (!inspection) {
    return null;
  }
  return {
    id: inspection.Id,
    name: decodeHtmlEntities(inspection.Name),
    recordType: convertRecordType(inspection.RecordType),
    dateCreated: inspection.CreatedDate || '', // ISO Date
    dateScheduled: inspection.Scheduled_Start_Time__c || '', // ISO Date
    nextAppointment: convertAppointment(
      inspection.Next_Open_Job_Appointment__r,
    ),
    dateStart: inspection.Start_Time__c || '', // ISO Date
    dateEnd: inspection.End_Time__c || '', // ISO Date
    status: inspection.Status__c || '',
    dateCompleted: formatNonUtcDate(inspection.Date_Completed__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateOwnerNotified: formatNonUtcDate(
      inspection.Date_Owner_Notified__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateReadyInspect: formatNonUtcDate(
      inspection.Ready_to_Inspect_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateReadyToMoveIn: formatNonUtcDate(
      inspection.Ready_to_Move_In_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dataCalculatedReadyToInspect: formatNonUtcDate(
      inspection.Calculated_Ready_to_Inspect_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    internalNotes: decodeHtmlEntities(inspection.Internal_Notes__c),
    priority: inspection.Priority__c || '',
    isBillCreated: !!inspection.Bill_Created__c,
    isBillPaid: !!inspection.Bill_Paid__c,
    billTo: inspection.Bill_To__c || '',
    billAmount: inspection.Bill_Amount__c || '',
    property: convertProperty(inspection.Property__r),
    lockboxInstructions: decodeHtmlEntities(inspection.Lockbox_Instructions__c),
    lineItems: convertLineItems(
      inspection.Property_Inspection_Line_Items__r?.records || [],
    ),
  };
};

// ------------------------------------
export const convertInspections = (inspections = []) =>
  inspections.map((inspection) => convertInspection(inspection));

// ------------------------------------
export const convertListing = (listing) => {
  if (!listing) {
    return null;
  }

  return {
    id: listing.Id,
    name: decodeHtmlEntities(listing.Name),
    status: listing.Status__c || '',
    listingUrl: listing.Listing_URL__c,
    dateCreated: listing.CreatedDate || '', // ISO Date
    dateAvailable: formatNonUtcDate(listing.Available_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateOnTheMarket: listing.Date_On_The_Market__c || '', // ISO Date
    dateReadyToMarket:
      formatNonUtcDate(listing.Ready_to_Market_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    dateLastOwnerUpdate: formatNonUtcDate(
      listing.Date_Last_Owner_Update__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    daysSinceLastOwnerUpdate: listing.Days_Since_Last_Owner_Update__c || 0,
    ownerUpdateFrequencyDays: listing.Owner_Update_Frequency_in_Days__c || 0,
    recordType: convertRecordType(listing.RecordType),
    isNewProperty: !!listing.New_Property__c,
    marketingNotes: decodeHtmlEntities(listing.Marketing_Notes__c),
    specialTerms: listing.Special_Terms__c || '',
    selfShowing: listing.Self_Showing__c || '',
    agentShowing: listing.Agent_Showing__c || '',
    marketingRentalRate: listing.Marketing_Rental_Rate__c || 0,
    daysOnMarket: listing.Days_on_Market__c || 0,
    dateNextOpenTask:
      formatNonUtcDate(listing.Date_Next_Open_Task__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    nextOpenTask: listing.Next_Open_Task__c || '',
    nextOpenTaskUser: convertUser(listing.Next_Open_Task_User__r),
    marketingReportUrl: listing.Marketing_Report_URL__c || '',
    ownerDecision: listing.Owner_Decision__c || '',
    ownerComments: listing.Owner_Comments_c__c || '',
    isMoveInReady: !!listing.Move_In_Ready__c,
    moveInReadyDetails: listing.Move_In_Ready_Details__c || '',
    applicationUrl: listing.Application_URL__c || '',
    property: convertProperty(listing.Property__r),
    findigsApplicationsUrl: listing.Findigs_Applications_URL__c || '',
    findigsId: listing.Findigs_Id__c || '',
    market: listing.Market__c || '',
    marketingStatus: listing.Marketing_Status__c || 'Off',
    marketingDepositCalc: listing.Marketing_Deposit_Calc__c || 0,
    marketingCoordinator: convertUser(listing.Marketing_Coordinator__r),
    isDepositAlternativeAvailable: !!listing.Deposit_Alternative_Available__c,
    moveInIncentive: listing.Move_In_Incentive__c || '',
  };
};

// ------------------------------------
export const convertListings = (listings = []) =>
  listings.map((listing) => convertListing(listing));

// ------------------------------------
export const convertApplicationPet = (app) => {
  if (!app) {
    return null;
  }
  return {
    id: app.Id,
    name: app.Name || '',
    age: app.Age__c || '',
    breed: app.Breed__c || '',
    petType: app.Type__c || '',
    weight: app.Weight__c || '',
    isServiceAnimal: !!app.Service_Animal__c,
  };
};

// ------------------------------------
export const convertApplicationPets = (apps = []) =>
  apps.map((app) => convertApplicationPet(app));

// ------------------------------------
export const convertApprovalStep = (app) => {
  if (!app) {
    return null;
  }
  return {
    id: app.Id,
    comments: app.Comments || '',
    stepStatus: app.StepStatus || '',
  };
};

// ------------------------------------
export const convertApprovalSteps = (steps = []) =>
  steps.map((step) => convertApprovalStep(step));

// ------------------------------------
export const convertApprovalProcessDecision = (app) => {
  if (!app) {
    return null;
  }

  return {
    id: app.Id,
    dateCreated: app.CreatedDate || '', // ISO Date
    dateCompleted: app.CompletedDate || '', // ISO Date
    status: app.Status || '',
    submittedBy: convertUser(app.SubmittedBy),
    steps: convertApprovalSteps(app.Steps?.records || []),
  };
};

// ------------------------------------
export const convertApplication = (app) => {
  if (!app) {
    return null;
  }

  return {
    id: app.Id,
    name: decodeHtmlEntities(app.Name || ''),
    applicationName: decodeHtmlEntities(app.Application_Name__c || ''),
    status: app.Status__c || '',
    market: convertMarket(app.Market__r),
    dateCreated: app.CreatedDate || '', // ISO Date
    details: decodeHtmlEntities(app.Details__c || ''),
    applicationSummary: app.Application_Summary__c || '',
    dateDesiredMoveIn:
      formatNonUtcDate(app.Desired_Move_In_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    propertyListing: convertListing(app.Property_Listing__r),
    contract: convertTenantContract(app.Contract__r),
    account: convertAccount(app.Account__r),
    leasingAgent: convertUser(app.Leasing_Agent__r),
    findigsId: app.Findigs_Id__c || '',
    findigsEvaluationUrl: app.Findigs_Evaluation_URL__c || '',
    isFindigsSyncIssue: !!app.Findigs_Sync_Issue__c,
    property: convertProperty(app.Property__r),
    propertyManager: convertUser(app.Property_Manager__r),
    score: app.Score__c || 0,
    criminalHistory: decodeHtmlEntities(app.Criminal_History__c || ''),
    rentalHistory: decodeHtmlEntities(app.Rental_History__c || ''),
    idVerification: decodeHtmlEntities(app.ID_Verification__c || ''),
    creditHistory: decodeHtmlEntities(app.Credit_History__c || ''),
    incomeHistory: decodeHtmlEntities(app.Income_History__c || ''),
    agentNotes: decodeHtmlEntities(app.Agent_Notes__c || ''),
    declinedDetails: decodeHtmlEntities(app.Declined_Details__c || ''),
    minorOccupants: decodeHtmlEntities(app.Minor_Occupants__c || ''),
    pets: convertApplicationPets(app.Application_Pets__r?.records),
    dateReadyToMoveIn: formatNonUtcDate(
      app.Ready_to_Move_In_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dataCalculatedReadyToInspect: formatNonUtcDate(
      app.Calculated_Ready_to_Inspect_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateReadyToInspect: formatNonUtcDate(
      app.Ready_to_Inspect_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    approvalProcessDecision: convertApprovalProcessDecision(
      app.ApprovalProcessDecision,
    ),
    dateLastModified: app.LastModifiedDate || '',
  };
};

// ------------------------------------
export const convertApplications = (apps = []) =>
  apps.map((app) => convertApplication(app));

// ------------------------------------
export const convertOpportunity = (opp) => {
  if (!opp) {
    return null;
  }

  const properties = opp.Properties__r?.records || [];

  return {
    id: opp.Id,
    name: decodeHtmlEntities(opp.Name),
    isOcWelcomeLetterSent: !!opp.oc_Welcome_Letter_Sent__c,
    isOcPmIntroEmail: !!opp.oc_PM_Intro_Email__c,
    nextActionFollowup: opp.Next_Action_Followup__c || '',
    nextActionUser: convertUser(opp.Next_Action_User__r),
    dateNextAction:
      formatNonUtcDate(opp.Next_Action_Date__c, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    account: convertAccount(opp.Account),
    dateClosed: formatNonUtcDate(opp.CloseDate, 'MM/DD/YYYY') || '', // YYYY-MM-DD
    owner: convertUser(opp.Owner),
    market: convertMarket(opp.Market__r),
    leadSource: opp.LeadSource || '',
    stage: opp.StageName || '',
    rating: opp.Rating__c || '', // Hot, Warm
    probability: opp.Probability || '',
    dateCreated: opp.CreatedDate || '', // ISO Date
    dateLastActivity: opp.Date_Last_Activity__c || '', // ISO Date
    propertyId: opp.Property__c || '',
    docusignStatus: convertDocusignStatus(opp.DocuSign_Status__r),
    signOnDocument: opp.signOnDocument || null,
    contact1: convertContact(opp.Contact_1__r),
    contact2: convertContact(opp.Contact_2__r),
    contact3: convertContact(opp.Contact_3__r),
    numberOfProperties: opp.Number_of_Properties__c || 0,
    properties: properties.map(convertProperty),
    leadDescription: opp.Lead_Description__c || '',
    onboardingCoordinator: convertUser(opp.Onboarding_Coordinator__r),
  };
};

// ------------------------------------
export const convertRecurringWorkOrders = (wos = []) => {
  if (!wos) {
    return [];
  }
  return wos.map((wo) => convertRecurringWorkOrder(wo));
};

// ------------------------------------
export const convertRecurringWorkOrder = (wo) => {
  if (!wo) {
    return null;
  }

  return {
    id: wo.Id || null,
    isActive: !!wo.Active__c,
    name: wo.Name || '',
    workType: wo.Type__c || '',
    price: wo.Price__c || 0,
    vendorPrice: wo.Vendor_Price__c || 0,
    billingFrequency: wo.Frequency__c || '',
    responsible: wo.Responsible_Party__c || '',
    company: wo.Managing_Company__c || '',
    jobFrequency: wo.Job_Frequency__c || '',
    dateStart: formatNonUtcDate(wo.Start_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateEnd: formatNonUtcDate(wo.End_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateNextProcess: formatNonUtcDate(wo.Next_Process_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    vendorNotes: wo.Vendor_Notes__c || '',
    workOrderDetails: wo.Work_Order_Details__c || '',
    vendorId: wo.Vendor__c || '',
    billToId: wo.Bill_To__c || '',
    number: wo.Name || '',
    status: wo.Job_Frequency__c || '',
    type: wo.Type__c || '',
    dateCreated: formatDate(wo.CreatedDate, 'MM/DD/YYYY'),
  };
};

// -------------------------------------------
export const convertLockbox = (lockbox) => {
  if (!lockbox) {
    return null;
  }

  const { Location__c: location, Notes__c: notes } = lockbox;
  let combinedNotes = location ? `Location: ${location}` : '';
  if (notes) {
    combinedNotes += combinedNotes !== '' ? `. ${notes}` : notes;
  }

  return {
    id: lockbox.Id,
    code: lockbox.Code__c || '',
    lockboxType: lockbox.Type__c || 'Smart',
    name: decodeHtmlEntities(lockbox.Name),
    status: lockbox.Status__c || '',
    location: location || '',
    notes: decodeHtmlEntities(notes),
    combinedNotes: decodeHtmlEntities(combinedNotes),
    numberOfAssignedProperties: lockbox.Number_of_Assigned_Properties__c || 0,
    mostRecentAssignedProperty: convertProperty(
      lockbox.Most_Recent_Assigned_Property__r,
    ),
    market: convertMarket(lockbox.Market__r),
    condition: lockbox.Condition__c || '',
    inspector: convertUser(lockbox.Inspector__r),
  };
};

export const convertOwnerContracts = (contracts = []) => {
  const ownerContracts = contracts.filter(
    (c) => c.RecordType?.Name === 'Owner Agreement',
  );
  return ownerContracts.map((contract) => convertOwnerContract(contract));
};

export const convertLeaseContracts = (contracts = []) => {
  const leaseContracts = contracts.filter(
    (c) => c.RecordType?.Name === 'Lease Agreement',
  );
  return leaseContracts.map((contract) => convertTenantContract(contract));
};

// ------------------------------------
export const convertProperty = (property) => {
  if (!property) {
    return null;
  }

  const streetAddress = property.Street_Address__c || '';
  const zip = property.Zip_Code__c || '';
  const building = property.Building__c || '';
  const unit = property.Unit__c || '';

  const propertySlug = generatePropertySlug({
    streetAddress,
    building,
    unit,
    zip,
  });

  const converted = {
    id: property.Id,
    account: convertAccount(property.Account__r),
    name: decodeHtmlEntities(property.Name),
    occupancy: property.Occupancy__c || '',
    propertySlug,
    dateCreated: property.CreatedDate || '', // ISO Date
    fullAddress: property.Full_Address__c || '',
    propertyAttributes: property.Property_Attributes__c || '',
    squareFeet: property.Square_Footage__c || 0,
    floors: property.Number_of_Floors__c || 0,
    bedRooms: property.Number_of_Beds__c || 0,
    bathRooms: property.Number_of_Baths__c || 0,
    streetAddress,
    building,
    unit,
    city: property.City__c || '',
    state: property.State__c || '',
    zip,
    mailboxNumber: property.Mailbox_Number__c || '',
    isOcCcr: !!property.oc_CCR__c,
    isOcPsComplete: !!property.oc_PS_Complete__c,
    utilitiesConfirmationStatus:
      property.Utilities_Confirmation_Status__c || '',
    services: convertPropertyServices(property.Home_Warranty__r?.records),
    activeOwnerContract: convertOwnerContract(
      property.Active_Owner_Contract__r,
    ),
    mostRecentOwnerAgreement: convertOwnerContract(
      property.Most_Recent_Owner_Agreement__r,
    ),
    previousOwnerAgreement: convertOwnerContract(
      property.Previous_Owner_Agreement__r,
    ),
    activeTenantContract: convertTenantContract(
      property.Active_Tenant_Contract__r,
    ),
    mostRecentTenantAgreement: convertTenantContract(
      property.Most_Recent_Tenant_Agreement__r,
    ),
    previousTenantAgreement: convertTenantContract(
      property.Previous_Tenant_Agreement__r,
    ),
    gateCode: property.Code_Gate__c || '',
    garageCode: property.Code_Garage__c || '',
    alarmCode: property.Code_Alarm__c || '',
    poolCode: property.Code_Pool__c || '',
    frontDoorCode: property.Code_Front_Door__c || '',
    keyTag: convertPropertyKeyTag(property.Property_Key_Tag__r),
    propertyManager: convertUser(property.Property_Manager__r),
    assistant: convertUser(property.Property_Manager__r?.Assistant__r),
    leaseContracts: convertLeaseContracts(property.Contracts__r?.records || []),
    ownerContracts: convertOwnerContracts(property.Contracts__r?.records || []),
    workOrders: convertWorkOrders(property.Work_Orders__r?.records),
    inspections: convertInspections(property.Inspections__r?.records),
    listings: convertListings(property.Property_Listings__r?.records),
    applications: convertApplications(property.Applications__r?.records),
    recurringWorkOrders: convertRecurringWorkOrders(
      property.Recurring_Work_Orders__r?.records,
    ),
    opportunity: convertOpportunity(property.Opportunity__r),
    propertywareEntityId: property.Propertyware_Entity_ID__c || '',
    propertywareId: property.Propertyware_ID__c || '',
    propertywareAbbreviation: property.Propertyware_Abbreviation__c || '',
    matterportLink: property.Matterport_Link__c || '',
    yearBuilt: property.Year_Built__c || 0,
    isPropertyPool: !!property.Swimming_Pool__c,
    isFilterServiceNeeded: !!property.Filter_Service_Needed__c,
    acFilterSize: property.AC_Filter_Size__c || '',

    activePropertyInspections:
      property.Number_of_Active_Property_Inspections__c || 0,
    activePropertyListings: property.Number_of_Active_Property_Listings__c || 0,
    activeWorkOrders: property.Number_of_Active_Work_Orders__c || 0,

    lockbox: convertLockbox(property.Lockbox__r),
    entityType: property.entityType || '',
    marketName: property.Market__c || '',
    combinedUtilities: property.Combined_Utilities__c || '', //
    lat: property.Latitude__c || 0,
    lng: property.Longitude__c || 0,
    marketingDescription: property.Marketing_Description__c || '',
  };

  // NOTE: it will mutate property
  fixOutdatedMostRecentContract(converted);
  return converted;
};

// ------------------------------------
export const convertAccount = (account) => {
  if (!account) {
    return null;
  }

  return {
    id: account.Id,
    name: decodeHtmlEntities(account.Name),
    officialPortfolioName: decodeHtmlEntities(
      account.Official_Portfolio_Name__c,
    ),
    fullName: decodeHtmlEntities(account.Full_Name__c),
    email: account.Email__c || '',
    phone: account.Phone || '',
    isInactive: !!account.Inactive__c,
    vendorRelationship: (account.Vendor_Relationship__c || '').split(';'),
    paymentTerms: account.Payment_Terms__c || '',
    doingBusinessAs: decodeHtmlEntities(account.Doing_Business_As_Name__c),
    insuranceStatus: account.Insurance_Status__c || '',
    isUploadedInsuranceDocuments: !!account.Uploaded_Insurance_Documents__c,
    isUploadedW9Documents: !!account.Vendor_W9__c,
    recordType: convertRecordType(account.RecordType),
    contact1: convertContact(account.Contact_1__r),
    contact2: convertContact(account.Contact_2__r),
    contact3: convertContact(account.Contact_3__r),
    billingStreet: account.BillingStreet || '',
    billingUnit: account.Billing_Unit__c || '',
    billingCity: account.BillingCity || '',
    billingState: account.BillingState || '',
    billingPostalCode: account.BillingPostalCode || '',
    forwardingStreet: account.Forwarding_Street_Address__c || '',
    forwardingStreet2: account.Forwarding_Street_Address_2__c || '',
    forwardingCity: account.Forwarding_City__c || '',
    forwardingState: account.Forwarding_State__c || '',
    forwardingZip: account.Forwarding_Zip_Code__c || '',
    addressChange: !!account.TPT_Address_Change__c,
    dateAddressChange: formatNonUtcDate(
      account.TPT_Address_Change_Date__c,
      'MM/DD/YYYY',
    ),
    disengaged: !!account.TPT_Disengaged__c,
    dateDisengaged: formatNonUtcDate(
      account.TPT_Disengaged_Date__c,
      'MM/DD/YYYY',
    ),
    services: account.Services__c || '',
    website: account.Website || '',
    dateInsuranceExpiration: formatNonUtcDate(
      account.Insurance_Expiration_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    ein: formatEin(account.EIN__c || ''),
    rocNumber: account.ROC_Number__c || '',
    performanceRating: Number(account.Performance_Rating__c || 0),
    jobSchedulingType: account.Job_Scheduling_Type__c || '',
    description: decodeHtmlEntities(account.Description),
    market: convertMarket(account.Market__r),
    isEmergencyVendor: !!account.Emergency_Vendor__c,
    accountContactRelations: convertAccountContactRelations(
      account.AccountContactRelations?.records,
    ),
    vendorComplianceMammoth: account.Vendor_Compliance_Mammoth__c || '',
    vendorComplianceOnQ: account.Vendor_Compliance_On_Q__c || '',
    dateMammothAchAuthorization: formatNonUtcDate(
      account.Mammoth_ACH_Authorization_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateOnqVendorWaiver: formatNonUtcDate(
      account.On_Q_Vendor_Waiver_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateMammothSubcontractorAgreement: formatNonUtcDate(
      account.Mammoth_Subcontractor_Agreement_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateVendorW9: formatNonUtcDate(account.Vendor_W9_Date__c, 'MM/DD/YYYY'), // YYYY-MM-DD
    dateUploadedInsuranceDocuments: formatNonUtcDate(
      account.Uploaded_Insurance_Documents_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateOnqVendorsOwnerWaiver: formatNonUtcDate(
      account.On_Q_Vendors_Owner_Waiver_Date__c,
      'MM/DD/YYYY',
    ), // YYYY-MM-DD
    dateLastCompletedLineItemMammoth:
      account.Last_Completed_Line_Item_Mammoth__c || '', // ISO Date
    dateLastCompletedLineItemOnq:
      account.Last_Completed_Line_Item_On_Q__c || '', // ISO Date
    inactiveReason: account.Inactive_Reason__c || '',

    numberOfLineItemsMammothLastMonth:
      account.Number_of_Line_Items_Mammoth_Last_Month__c || 0,
    numberOfLineItemsMammothLastYear:
      account.Number_of_Line_Items_Mammoth_Last_Year__c || 0,
    numberOfLineItemsOnqLastMonth:
      account.Number_of_Line_Items_On_Q_Last_Month__c || 0,
    numberOfLineItemsOnqLastYear:
      account.Number_of_Line_Items_On_Q_Last_Year__c || 0,
  };
};

// ------------------------------------
export const convertRoundRobinAssignmentGroup = (group) => {
  if (!group) {
    return null;
  }
  return {
    id: group.Id,
    name: decodeHtmlEntities(group.Name),
  };
};

// ------------------------------------
export const convertRoundRobinAssignee = (assignee) => {
  if (!assignee) {
    return null;
  }

  return {
    id: assignee.Id,
    name: decodeHtmlEntities(assignee.Name),
    isActive: !!assignee.Is_Active__c,
    isNotifyNewLeads: !!assignee.Notify_New_Leads__c,
    dateLastAssignedLead: assignee.Last_Assigned_Lead__c || '', // ISO Date
    dateLastAssignedOpportunity: assignee.Last_Assigned_Opportunity__c || '', // ISO Date
    dateLastAssigned: assignee.Last_Assigned__c || '', // ISO Date
    propertyTypeManaged: assignee.Property_Type_Managed__c || '',
    managedPropertyCap: assignee.Managed_Property_Cap__c || 10,
    roundRobinAssignmentGroup: convertRoundRobinAssignmentGroup(
      assignee.Round_Robin_Assignment_Group__r,
    ),
    user: convertUser(assignee.User__r),
  };
};

// ------------------------------------
export const convertLead = (lead) => {
  if (!lead) {
    return null;
  }

  return {
    id: lead.Id || null,
    name: lead.Name || '',
    email: lead.Email || '',
    phone: lead.Phone || '',
    description: lead.Description || '',
    leadSource: lead.LeadSource || '',
    dateCreated: lead.CreatedDate || '', // ISO Date
    status: lead.Status || '',
    market: convertMarket(lead.Market__r),
    opportunity: convertOpportunity(lead.ConvertedOpportunity),
    owner: convertUser(lead.Owner),
    fullAddress: lead.Full_Address__c || '',
    propertyId: lead.Property__c || '',
    property: convertProperty(lead.Property__r),
    leadSpeed: lead.Lead_Speed__c || '',
    dateFirstActivity: lead.Date_First_Activity__c, // ISO Date
    dateLastActivity: lead.Date_Last_Activity__c, // ISO Date
    dateNextAction: lead.Next_Action_Date__c, // YYYY-MM-DD
    nextActionFollowup: lead.Next_Action_Followup__c || '',
    unqualifiedReason: lead.Unqualified_Reason__c || '',
    unqualifiedDescription: lead.Unqualified_Description__c || '',
    convertedOpportunityId: lead.ConvertedOpportunityId || '',
  };
};

// ------------------------------------
export const convertLeads = (leads = []) => {
  if (!leads) {
    return [];
  }
  return leads.map((lead) => convertLead(lead));
};

// ------------------------------------
export const convertInspectionFeedback = (inspection) => ({
  rating: inspection.Feedback_Rating__c || 1,
  feedback: inspection.Feedback_Details__c || '',
  lastModifiedBy: inspection.Feedback_Last_Modified_By__r?.Email || '',
  lastModifiedDate: formatDate(
    inspection.Feedback_Last_Modified_Date__c,
    'MM/DD/YYYY hh:mm',
  ),
});

// -------------------------------------------
const getNumberOrNull = (value) => (value === null ? null : Number(value || 0));

export const convertFeedback = (feedback) => ({
  id: feedback.Id,
  beNotifiedAboutOffMarketListings:
    feedback.Be_Notified_About_Off_Market_Listings__c || '',
  biggestChallengeOwningRentalOther:
    feedback.Biggest_Challenge_Owning_Rental_Other__c || '',
  biggestChallengeOwningRental:
    feedback.Biggest_Challenge_Owning_Rental__c || '',
  dateCompleted: feedback.Date_Completed__c || '',
  doYouCurrentlyOwnARentalProperty:
    feedback.Do_You_Currently_Own_A_Rental_Property__c || '',
  employeesAreProfessional: getNumberOrNull(
    feedback.Employees_Are_Professional__c, // null, 0 - 5
  ),
  employeesCommunicateClearly: getNumberOrNull(
    feedback.Employees_Communicate_Clearly__c,
  ), // null, 0 - 5
  employeesRespondInTimelyManner: getNumberOrNull(
    feedback.Employees_Respond_In_Timely_Manner__c,
  ), // null, 0 - 5
  howDoYouCurrentlyManageOther:
    feedback.How_Do_You_Currently_Manage_Other__c || '',
  howDoYouCurrentlyManage: feedback.How_Do_You_Currently_Manage__c || '',
  howInvolvedWithManagementOther:
    feedback.How_Involved_With_Management_Other__c || '',
  howLikelyToRecommendOnQ: getNumberOrNull(
    feedback.How_Likely_To_Recommend_On_Q__c, // null, 0 - 10
  ),
  howSatisfiedWithAgent5Star: getNumberOrNull(
    feedback.How_Satisfied_With_Agent_5_Star__c,
  ),
  howSatisfiedWithExperience5Star: getNumberOrNull(
    feedback.How_Satisfied_With_Experience_5_Star__c,
  ), // null, 1 - 5
  howSatisfiedWithExperience: feedback.How_Satisfied_With_Experience__c || '',
  howToPurchaseInvestmentProperties:
    feedback.How_To_Purchase_Investment_Properties__c || '',
  intendOnPurchasingPropertiesOther:
    feedback.Intend_On_Purchasing_Properties_Other__c || '',
  intendOnPurchasingProperties:
    feedback.Intend_On_Purchasing_Properties__c || '',
  intendToSellPropertiesOther:
    feedback.Intend_To_Sell_Properties_Other__c || '',
  intendToSellProperties: feedback.Intend_To_Sell_Properties__c || '',
  questionsAboutInvestmentOpportunities:
    feedback.Questions_About_Investment_Opportunities__c || '',
  reasonForCancelingOwnerOther:
    feedback.Reason_For_Canceling_Owner_Other__c || '',
  reasonForCancelingOwner: feedback.Reason_For_Canceling_Owner__c || '',
  reasonForInvestingOther: feedback.Reason_For_Investing_Other__c || '',
  reasonForInvesting: feedback.Reason_For_Investing__c || '',
  sourceUsedToFindPMCompanyOther:
    feedback.Source_Used_To_Find_PM_Company_Other__c || '',
  sourceUsedToFindPMCompany: feedback.Source_Used_To_Find_PM_Company__c || '',
  top3PMCompanyTraits: feedback.Top_3_PM_Company_Traits__c || '',
  whatCouldOnQDoToEarnBusiness:
    feedback.What_Could_On_Q_Do_To_Earn_Business__c || '',
  whatCouldOnQDoToImprove: feedback.What_Could_On_Q_Do_To_Improve__c || '',
  whenPurchaseNextInvestmentProperty:
    feedback.When_Purchase_Next_Investment_Property__c || '',
  recordType: convertRecordType(feedback.RecordType),
});

// -------------------------------------------
export const convertPropertyKeyTag = (propertyKeyTag) => {
  if (!propertyKeyTag) {
    return null;
  }

  return {
    id: propertyKeyTag.Id || '',
    name: decodeHtmlEntities(propertyKeyTag.Name),
    group: propertyKeyTag.Group__c || '',
    tagNumber: propertyKeyTag.Tag_Number__c || '',
    status: propertyKeyTag.Status__c || '',
    numberOfAssignedProperties:
      propertyKeyTag.Number_of_Assigned_Properties__c || 0,
    propertyFullAddress: propertyKeyTag.Property_Full_Address__c || '',
    market: convertMarket(propertyKeyTag.Market__r),
    mostRecentAssignedProperty: convertProperty(
      propertyKeyTag.Most_Recent_Assigned_Property__r,
    ),
    isIssueDetected: !!propertyKeyTag.Issue_Detected__c,
    issueNotes: propertyKeyTag.Issue_Notes__c || '',
  };
};

/**
 * Remove all the unnecessary characters
 */
const slugify = (component) =>
  String(component || '')
    .replace(/[^a-z0-9]+/gi, '')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .toLowerCase();

/**
 * Generate property slug for a report url
 */
export const generatePropertySlug = ({
  streetAddress,
  zip,
  building,
  unit,
}) => {
  // This will create a relatively familiar URL route for the customer.
  const sStreetAddress = slugify(streetAddress);
  const sZip = slugify(zip);
  const sBuilding = building ? `-${slugify(building)}` : '';
  const sUnit = unit ? `-${slugify(unit)}` : '';
  return `${sStreetAddress}-${sZip}${sBuilding}${sUnit}`;
};

// ------------------------------------
export const convertTask = (task) => {
  if (!task) {
    return null;
  }

  return {
    id: task.Id || null,
    dateCreated: task.CreatedDate || '', // ISO Date
    dateActivity: formatNonUtcDate(task.ActivityDate, 'MM/DD/YYYY'), // YYYY-MM-DD
    description: task.Description || '',
    owner: convertUser(task.Owner),
    priority: task.Priority || '',
    status: task.Status || '',
    subject: task.Subject || '',
    taskSubtype: task.TaskSubtype || '',
    type: task.Type || '',
    whatId: task.WhatId || '',
    whoId: task.WhoId || '',
  };
};

// ------------------------------------
export const convertTasks = (tasks = []) => {
  if (!tasks) {
    return [];
  }
  return tasks.map((task) => convertTask(task));
};
