import React, { memo } from 'react';
import { array, string, object, func, oneOfType, bool } from 'prop-types';
import { Typography, Autocomplete, Chip } from '@mui/material';
import Input from 'components/CustomTextField';

const sxGroupLabel = {
  '&.MuiAutocomplete-groupLabel': { color: 'black' },
};

const CustomAutocomplete = ({
  name,
  type = 'multiple',
  validation = null,
  value = null,
  onChange,
  options = [],
  placeholder = '',
  label = '',
  ...props
}) => {
  const onValueChange = (e, val) =>
    onChange(
      name,
      Array.isArray(val) ? val.map((i) => i?.value ?? i) : val?.value,
    );

  let selectedValue = value;
  if (type !== 'multiple') {
    selectedValue = options.find((v) => v?.value === value);
  }

  return (
    <Autocomplete
      {...props}
      value={selectedValue || null}
      multiple={type === 'multiple'}
      size="small"
      options={options}
      disableCloseOnSelect={type === 'multiple'}
      getOptionLabel={(option) => (option?.label ? option.label : option)}
      isOptionEqualToValue={(option, val) =>
        (option?.value ?? option) === (val?.value ?? val)
      }
      onChange={onValueChange}
      renderInput={(params) => (
        <Input
          {...params}
          required={!!props.required}
          label={label}
          name={name}
          margin="none"
          validation={validation}
          placeholder={placeholder}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            sx={{ height: 24 }}
            {...getTagProps({ index })}
            key={option.value ? option.value : option}
            label={option.label ?? option}
          />
        ))
      }
      renderOption={(optionsProps, option) => (
        <li {...optionsProps} key={option.value ? option.value : option}>
          <Typography variant="body2" noWrap>
            {option.label ?? option}
          </Typography>
        </li>
      )}
      sx={sxGroupLabel}
    />
  );
};

CustomAutocomplete.propTypes = {
  options: array,
  type: string,
  placeholder: string,
  label: string,
  validation: object,
  value: oneOfType([array, string]),
  name: string.isRequired,
  required: bool,
  onChange: func.isRequired,
};

export default memo(CustomAutocomplete);
